/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from 'react'
import { StepProps } from '../ISignupModels'
import { KTIcon } from '../../../../_metronic/helpers'
import { SubscriptionPlan } from '../../../pages/analysis/core/_models'
import { getSubscriptionPlans } from '../../../pages/analysis/core/_requests'

const Step2 = ({ data, updateData }: StepProps) => {

  const [subscriptionPlans, setSubscriptionPlans] = useState<SubscriptionPlan[]>()

  const [currentState, setCurrentState] = useState<'month' | 'annual'>('month')
  const [selectedPlan, setSelectedPlan] = useState<SubscriptionPlan>()

  useEffect(() => {
    getSubscriptionPlans().then((res) => {
      const plans = res.data
      setSubscriptionPlans(res.data)
      setSelectedPlan(plans?.find(plan => plan.default || plans[0]))
      updateData({
        appPlan: {
          bmix_plan_id: selectedPlan?.bmix_plan_id,
          stripe_monthly_id: selectedPlan?.stripe_monthly_id
        },
      })
    })
  }, []);

  const handlePlanChange = (planTitle: string) => {
    const plan = subscriptionPlans?.find(p => p.title === planTitle);
    if (plan) {
      setSelectedPlan(plan)
      updateData({
        appPlan: {
          bmix_plan_id: plan.bmix_plan_id,
          stripe_monthly_id: plan.stripe_monthly_id
        },
      })
    }
  };

  return (
    <div className='pb-5' data-kt-stepper-element='content'>

      <div className='modal-body pt-0 pb-5 px-5 px-xl-0'>
        <div className='d-flex flex-column'>
          {/* <div className='nav-group nav-group-outline mx-auto' data-kt-buttons='true'>
            <a
              href='#'
              className={
                'btn btn-color-gray-400 btn-active btn-active-secondary px-6 py-3 me-2 ' +
                (currentState === 'month' && 'active')
              }
              onClick={() => {
                setCurrentState('month')
              }}
              data-kt-plan='month'
            >
              Mensile
            </a>
            <a
              href='#'
              className={
                'btn btn-color-gray-400 btn-active btn-active-secondary px-6 py-3 me-2 ' +
                (currentState === 'annual' && 'active')
              }
              onClick={() => {
                setCurrentState('annual')
              }}
              data-kt-plan='annual'
            >
              Annuale
            </a>
          </div> */}

          <div className='row mt-10'>
            <div className='col-lg-6 mb-10 mb-lg-0'>
              <div className='nav flex-column'>
                {subscriptionPlans?.map((plan, index) => {
                  return (
                    <div
                      onClick={() => {
                        handlePlanChange(plan.title)
                      }}
                      className={
                        `nav-link btn btn-outline btn-outline-dashed btn-color-dark d-flex flex-stack text-start p-6 ` +
                        (index !== subscriptionPlans?.length - 1 && 'mb-6 ') +
                        (plan.default && 'active ') +
                        (!plan.custom && 'btn-active btn-active-primary ')
                      }
                      data-bs-toggle='tab'
                      data-bs-target={`#kt_upgrade_plan_${index}`}
                      key={index}
                    >
                      <div className='d-flex align-items-center me-2'>
                        <div className='form-check form-check-custom form-check-solid form-check-success me-6'>
                          <input
                            className='form-check-input'
                            type='radio'
                            name='plan'
                            value={plan.title}
                            checked={selectedPlan?.title === plan.title}
                            onChange={(e) => handlePlanChange(e.target.value)}
                          />
                        </div>

                        <div className='flex-grow-1'>
                          <div className='d-flex align-items-center fs-2 fw-bold flex-wrap'>
                            {plan.title}
                          </div>
                          <div className='fw-semibold opacity-50'>{plan.subTitle}</div>
                          {plan.label && (
                            <span className='badge badge-light-success mt-2 fs-7'>
                              {plan.label}
                            </span>
                          )}
                        </div>
                      </div>

                      <div className='ms-5 text-xl-end'>
                        <span className='mb-2'>€</span>

                        <span className='fs-2hx fw-bold'>
                          {currentState === 'month' ? plan.discountedPriceMonth : plan.discountedPriceAnnual}
                        </span>

                        <span className='fs-7 opacity-50'>
                          /<span data-kt-element='period'>{currentState === 'month' ? 'Mese' : 'Anno'}</span>
                        </span>
                        <div>
                          <span className='mb-2 opacity-75'>dal 2° mese €</span>

                          <span className='fs-1x fw-bold'>
                            {currentState === 'month' ? plan.priceMonth : plan.priceAnnual}
                          </span>

                          <span className='fs-7 opacity-50'>
                            /<span data-kt-element='period'>{currentState === 'month' ? 'Mese' : 'Anno'}</span>
                          </span>
                        </div>
                      </div>
                    </div>
                  )
                })}
              </div>
            </div>

            <div className='col-lg-6'>
              <div className='tab-content rounded bg-light p-10'>
                <div key={`custom_${selectedPlan?.id}`}>
                  <div
                    className={`tab-pane fade active show`}
                    id={`kt_upgrade_plan_${selectedPlan?.title}`}
                    key={selectedPlan?.id}>

                    <div className='pb-5'>
                      <h2 className='fw-bold text-dark'>Cosa include il tuo piano?</h2>

                      <div className='text-gray-400 fw-semibold'>
                        {selectedPlan?.description}
                      </div>
                    </div>

                    <div className='pt-1'>
                      {selectedPlan?.features!.map((feature, i) => {
                        return (
                          <div
                            className={
                              `d-flex align-items-center` +
                              (i !== selectedPlan.features!.length - 1 && ' mb-7')
                            }
                            key={`${i}-${feature.title}`}
                          >
                            {feature.supported && (
                              <>
                                <span className='fw-semibold fs-5 text-gray-700 flex-grow-1'>
                                  {feature.title}
                                </span>

                                <KTIcon
                                  iconName='check-circle'
                                  className='fs-1 text-success'
                                />
                              </>
                            )}
                            {!feature.supported && (
                              <>
                                <span className='fw-semibold fs-5 text-gray-400 flex-grow-1'>
                                  {feature.title}
                                </span>
                                <KTIcon iconName='cross-circle' className='fs-1' />
                              </>
                            )}
                          </div>
                        )
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export { Step2 }
