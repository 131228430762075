import { useEffect } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import { useAuth } from './core/Auth'
export function Logout() {
  const { logout } = useAuth();

  useEffect(() => {
    const performLogout = async () => {
      await logout();
      document.location.reload();
    };

    performLogout();
  }, [logout]);

  return (
    <Routes>
      <Route path="*" element={<Navigate to="/auth" />} />
    </Routes>
  )
}
