import React, { useState, useEffect } from 'react'
// import { getAnalysisRelevantUrls, getProjectUrls, updateUrl } from './core/_requests'
import { Link } from 'react-router-dom'
import { deleteProduct, editProduct, getProducts, getSuggestedProducts } from './core/_requests'
import { Button } from 'react-bootstrap'
import { ProductModel } from './core/_models'
import { EditProductModal } from './modals/EditProductModal'
// import { AddUrlModal } from './modals/AddUrlModal'

const SuggestedProductsPage: React.FC = () => {

  const [loading, setLoading] = useState(false)
  const [products, setProducts] = useState<Array<any> | []>([])

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      setLoading(true);
      await getSuggestedProducts(1, 500).then((res) => {
        setProducts(res.data)
        setLoading(false);
      });
    } catch (error) {
      console.error('Error fetching data:', error);
      setLoading(false);
    }
  };

  const handleFavouriteButtonClick = async (product: ProductModel) => {
    try {
      setLoading(true);
      await editProduct(product._id, product.productName, product.productUrl, !product.analyze)
      await getSuggestedProducts(1, 500).then((res) => {
        setProducts(res.data)
        setLoading(false);
      });
    } catch (error) {
      console.error(error)
      setLoading(false)
    }
  };

  const handleDeleteButtonClick = async (id: String) => {
    try {
      await deleteProduct(id)
      fetchData()
    } catch (error) {
      console.error(error)
    }
  };

  return (
    <>
      <div className="d-flex flex-column flex-column-fluid bg-light">
        <div id="kt_app_content" className="app-content flex-column-fluid" data-kt-app-page-loading-enabled="true" data-kt-app-page-loading={loading ? 'on' : 'off'}>
          <div className="page-loader flex-column bg-dark bg-opacity-25">
            <span className="spinner-border text-primary" role="status">
              <span className="visually-hidden">Loading...</span>
            </span>
          </div>
          <div className="d-flex">
            <div className="app-sidebar-primary d-none d-lg-flex" key={Math.random()}>
              <div className="app-sidebar-nav flex-grow-1 hover-scroll-overlay-y px-5 pt-2" style={{ height: "106px" }}>
                <ul className="nav" role="tablist">
                  <li className="nav-item py-1" role="presentation">
                    <Link to='/products/mine' className='nav-link py-6 px-4 btn' role="tab">
                      <i className="ki-outline ki-filter-tablet fs-1"></i>
                      <span className="pt-2 fs-9 fs-lg-7 fw-bold">I miei prodotti</span>
                    </Link>
                  </li>
                  <li className="nav-item py-1" role="presentation">
                    <Link to='/products/suggested' className='nav-link py-6 px-4 btn active' role="tab">
                      <i className="ki-outline ki-graph-3 fs-1"></i>
                      <span className="pt-2 fs-9 fs-lg-7 fw-bold">Prodotti suggeriti</span>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div id="kt_app_content_container" className="app-container container-fluid">
              <div className="d-flex flex-row flex-column-fluid">
                <div className="vh-75 w-100">
                  <div className="row gy-5 g-xl-10">
                    <div className="col-xl-12 mb-xl-10">

                      <div className='card card-flush mb-4 d-lg-none'>
                        <div className="app-sidebar-primary" key={Math.random()}>
                          <div className="app-sidebar-nav flex-grow-1 hover-scroll-overlay-y">
                            <ul className="nav d-flex flex-row" role="tablist">
                              <li className="nav-item" role="presentation">
                                <Link to='/products/mine' className='nav-link py-4 px-10 me-4 btn' role="tab">
                                  <i className="ki-outline ki-filter-tablet fs-1"></i>
                                  <span className="pt-2 fs-9 fs-lg-7 fw-bold">I miei prodotti</span>
                                </Link>
                              </li>
                              <li className="nav-item py-1" role="presentation">
                                <Link to='/products/suggested' className='nav-link py-4 px-10 btn active' role="tab">
                                  <i className="ki-outline ki-graph-3 fs-1"></i>
                                  <span className="pt-2 fs-9 fs-lg-7 fw-bold">Prodotti suggeriti</span>
                                </Link>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>

                      <div className="card card-flush">
                        <div className="card-header pt-7">
                          <h3 className="card-title align-items-start flex-column">
                            <span className="card-label fw-bold text-gray-800">Prodotti competitors</span>

                          </h3>
                          <div className="card-toolbar">
                            {/* <button type="button" className="btn btn-sm btn-primary" data-bs-toggle="modal" data-bs-target={"#kt_modal_add_url"}>
                              <i className="bi bi-plus-lg fs-6"></i>Aggiungi</button> */}
                            {/* <AddUrlModal handleClose={() => { window.location.reload(); }} /> */}
                          </div>
                        </div>
                        <div className="card-body pt-0 my-5">
                          <div className="tab-content">
                            <div className="tab-pane fade active show" id="kt_stats_widget_6_tab_1">
                              <div className="table-responsive">
                                <table className="table table-row-dashed align-middle gs-0 gy-4 my-0">
                                  <thead>
                                    <tr className="text-start text-gray-500 fw-bold fs-7 text-uppercase gs-0">
                                      <th className="min-w-100px sorting">NOME PRODOTTO</th>
                                      <th className="min-w-50px sorting"></th>
                                      <th className="text-end min-w-200px sorting_disabled">COSA FARE</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {products.map((product, index) => {
                                      return (
                                        <tr>
                                          <td>
                                            <Link to={'/product/' + product?._id}>
                                              <div className="d-flex align-items-center">
                                                <div className="d-flex justify-content-start flex-column">
                                                  <span className="text-dark fw-bold mb-1 fs-6">{product?.productName}</span>
                                                  <span className="text-muted fw-semibold d-block fs-7">{product?.productOwner?.brandName}</span>
                                                </div>
                                              </div>
                                            </Link>
                                          </td>
                                          <td>
                                            <span className={`py-2 px-2 fs-8 fw-semibold  ${product?.userId !== undefined ? "text-primary" : "text-warning"}`}>
                                              <i className={`fa fa-regular me-2 fs-8  ${product?.userId !== undefined ? "fa-user text-primary" : "fa-star text-warning"}`}></i>
                                              {product?.userId !== undefined ? "INSERITO MANUALMENTE" : "SUGGERITO DA BRAINMIX"}</span>
                                          </td>
                                          <td className="text-end">
                                            {<Button className="btn btn-sm btn-icon btn-delete btn-light-warning me-2" data-bs-toggle="modal" data-bs-target={"#kt_modal_edit_product_" + index}>
                                              <i className="fa fa-edit fa-regular"></i>
                                            </Button>}
                                            <EditProductModal product={product} index={index} handleClose={() => { window.location.reload(); }} />
                                            <Button className="btn btn-sm btn-icon btn-delete btn-light-danger me-2"
                                              onClick={() => { if (window.confirm('Sei sicuro di voler eliminare questo elemento?')) handleDeleteButtonClick(product?._id) }}>
                                              <i className="fa fa-trash-can fa-regular">
                                              </i>
                                            </Button>
                                            <Button className="btn btn-sm btn-icon btn-light-primary me-2" href={product?.productUrl} target='_blank' rel="noreferrer">
                                              <i className={"fas fa-arrow-up-right-from-square"}>
                                              </i>
                                            </Button>
                                            <a className={`btn btn-sm btn-icon btn-bg-light ${product?.analyze ? "btn-color-success" : " btn-color-gray-500"}`}
                                              onClick={() => handleFavouriteButtonClick(product)}>
                                              <i className={`fa fs-3 ${product?.analyze ? "fa-toggle-on" : "fa-toggle-off"} `}></i>
                                            </a>
                                          </td>
                                        </tr>
                                      )
                                    })}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="hover-overlay-y-custom d-none d-lg-flex">
              <div className=" px-5 px-lg-10">
                <div className=" fade active show" id="kt_app_sidebar_secondary_collections" role="tabpanel" >
                  <div className="card card-reset card-p-0">
                    <div className="card-body d-flex flex-column flex-center">

                      <div className="mb-2 text-center">

                        <h4 className="fw-semibold text-gray-800 text-center lh-lg">Qualche dato</h4>
                        <span className="text-center">Di seguito qualche informazione sul totale dei dati generati</span>

                        <div className="py-10">
                          <div className="col-xl-12">
                            <div className="card p-4 card-xxl-stretch bg-body">
                              <div className="card-body d-flex flex-column align-items-start justify-content-between">
                                <i className="ki-duotone ki-rocket text-success fs-2hx ms-n1 flex-grow-1">
                                  <span className="path1"></span><span className="path2"></span>
                                </i>
                                <div className="d-flex flex-column align-items-start">
                                  <div className="text-dark fw-bold fs-1 mb-0 mt-5">{products.length}</div>
                                  <div className="text-muted fw-semibold fs-6">Tutti i prodotti</div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-xl-12 pt-10">
                            <div className="card p-4 card-xxl-stretch bg-body">
                              <div className="card-body d-flex flex-column align-items-start justify-content-between">
                                <i className="ki-duotone ki-heart text-success fs-2hx ms-n1 flex-grow-1">
                                  <span className="path1"></span><span className="path2"></span>
                                </i>
                                <div className="d-flex flex-column align-items-start">
                                  <div className="text-dark fw-bold fs-1 mb-0 mt-5">{products.filter((product) => product.analyze === true).length}</div>
                                  <div className="text-muted fw-semibold fs-6">Prodotti da analizzare</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default SuggestedProductsPage