import { FC, useEffect, useState } from 'react'
import { SwapperComponent } from '../../../assets/ts/components'
import { getUserByToken, useAuth } from '../../../../app/modules/auth'
import { updateSelectedProject } from '../../../../app/modules/profile/core/_requests'
import { MainMenu } from './MainMenu'
import { MenuItem } from './MenuItem'
import { Link } from "react-router-dom"
import { KTIcon } from '../../../helpers'
import clsx from 'clsx'

const toolbarButtonMarginClass = 'ms-1 ms-lg-3',
  toolbarButtonHeightClass = 'btn-active-light-primary btn-custom w-30px h-30px w-md-40px h-md-40p',
  toolbarButtonIconSizeClass = 'fs-1'

const Header: FC = () => {

  const { currentUser, saveAuth, setCurrentUser } = useAuth()

  const [selectedProject, setSelectedProject] = useState(currentUser?.selectedProject?._id || '');

  // Event handler for select change
  const handleProjectChange = async (project: any) => {
    const selectedProjectId = project.target.value;
    setSelectedProject(selectedProjectId);
    await updateSelectedProject(selectedProjectId)
    const { data: auth } = await getUserByToken()
    saveAuth(auth)
    setCurrentUser(auth.user)
    window.location.reload();
  };

  useEffect(() => {
    SwapperComponent.reinitialization()
  }, [])

  return (
    <div
      id='kt_header_menu'
      className='header-menu align-items-stretch'
      data-kt-drawer='true'
      data-kt-drawer-name='header-menu'
      data-kt-drawer-activate='{default: true, lg: false}'
      data-kt-drawer-overlay='true'
      data-kt-drawer-width="{default:'200px', '300px': '250px'}"
      data-kt-drawer-direction='start'
      data-kt-drawer-toggle='#kt_header_menu_mobile_toggle'
      data-kt-swapper='true'
      data-kt-swapper-mode='prepend'
      data-kt-swapper-parent="{default: '#kt_body', lg: '#kt_header_nav'}"
    >
      <div className='menu menu-lg-rounded menu-column menu-lg-row menu-state-bg menu-title-gray-700 menu-state-title-primary menu-state-icon-primary menu-state-bullet-primary menu-arrow-gray-400 fw-bold my-5 my-lg-0 align-items-stretch'
        id='#kt_header_menu'
        data-kt-menu='true' >
        <MenuItem title='Dashboard' to='/dashboard' />
        <MainMenu />
        <div className={clsx('d-flex align-items-center ', toolbarButtonMarginClass)}>
          <div className="form-floating">
            <select className="form-select form-select-solid" id="floatingSelect" aria-label="Seleziona progetto" onChange={handleProjectChange}>
              {currentUser?.followedProjects?.map((project, index) => {
                return (
                  <option
                    key={project._id}
                    value={project._id}
                    selected={project._id === selectedProject}
                  >{project.brandName}</option>
                )
              })}
            </select>
            <label>Seleziona progetto</label>
          </div>
          <Link to='/account/projects' >
            <div
              className={clsx(
                'btn btn-icon btn-active-light-primary btn-custom',
                toolbarButtonHeightClass
              )}
              id='kt_activities_toggle' >
              <KTIcon iconName='abstract-25' className={toolbarButtonIconSizeClass} />
            </div>
          </Link>
        </div>
      </div>
    </div>
  )
}

export { Header }
