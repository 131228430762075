import axios from 'axios'
import { UserModel } from '../../auth/core/_models'

const API_URL = process.env.REACT_APP_API_URL

export const REQUEST_UPDATE_SELECTED_PROJECT_URL = `${API_URL}/profile/updateSelectedProject`
export const REQUEST_UPDATE_FOLLOWED_PROJECT_URL = `${API_URL}/profile/updateFollowedProjects`
export const REQUEST_ADD_PROJECT_URL = `${API_URL}/projects`
export const REQUEST_ADD_PROJECT_COMPETITOR_URL = `${API_URL}/projects/competitor`
export const REQUEST_CHANGE_PASSWORD_URL = `${API_URL}/profile/changePassword`

export function updateSelectedProject(selectedProject: string) {
  return axios.patch<UserModel>(REQUEST_UPDATE_SELECTED_PROJECT_URL, {
    selectedProject,
  })
}

export function updateFollowedProjects(projectId: string) {
  return axios.patch<UserModel>(REQUEST_UPDATE_FOLLOWED_PROJECT_URL, {
    projectId,
  })
}

export function addProject(name: string, domain: string) {
  const formData = new URLSearchParams();
  formData.append('name', name);
  formData.append('domain', domain);
  formData.append('follow', 'true');
  return axios.post<UserModel>(REQUEST_ADD_PROJECT_URL, formData, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    }
  })
}

export function addProjectCompetitor(projectId: string, name: string, domain: string) {
  const formData = new URLSearchParams();
  formData.append('projectId', projectId);
  formData.append('name', name);
  formData.append('domain', domain);
  return axios.post<UserModel>(REQUEST_ADD_PROJECT_COMPETITOR_URL, formData, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    }
  })
}

export function changePassword(oldPassword: string, newPassword: string) {
  return axios.post<{ result: boolean }>(REQUEST_CHANGE_PASSWORD_URL, {
    oldPassword, newPassword
  }, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
      "X-Requested-With": "XMLHttpRequest"
    }
  }
  )
}
