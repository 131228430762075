import { useEffect, useState } from 'react'
import { getUserByToken, registerComplete } from './core/_requests';
import { useIntl } from 'react-intl';
import { SignupCompleteModel, SignupCompleteProjectModel, UserModel } from './core/_models';
import { UrlModel } from '../../pages/analysis/core/_models';
import { Button } from 'react-bootstrap';
import { AddSignupUrlModal } from './modals/AddSignupUrlModal';
import { AddSignupMyUrlModal } from './modals/AddSignupMyUrlModal';
import { HandleApiError } from '../../core/helpers/errorHandling';

interface FormState {
    name: string
    domain: string
    myUrls: UrlModel[]
    urls: UrlModel[]
}

const SignupCompletePage = () => {

    const intl = useIntl()
    const [user, setUser] = useState<UserModel>()
    const [requestTypesOptions, setRequestTypesOptions] = useState<any[]>([])
    const [formErrors, setFormErrors] = useState<Record<string, string>>()
    const [loading, setLoading] = useState(false)
    const [success, setSuccess] = useState(false)


    useEffect(() => {
        getUserByToken().then((res) => {
            setUser(res.data.user)
        });
    }, []);

    const [formData, setFormData] = useState<FormState>({
        name: '',
        domain: '',
        myUrls: [],
        urls: []
    });

    const handleFormError = (field: string, errorMessage: string) => {
        setFormErrors((prevErrors) => ({
            ...prevErrors,
            [field]: errorMessage,
        }));
    };

    const handleDeleteMyUrlsButtonClick = async (index: number) => {
        try {

            const updatedMyUrls = [...formData.myUrls]
            updatedMyUrls.splice(index, 1)
            setFormData({
                ...formData,
                myUrls: updatedMyUrls,
            })
        } catch (error) {
            console.error(error)
        }
    }

    const handleDeleteUrlsButtonClick = async (index: number) => {
        try {

            const updatedUrls = [...formData.urls]
            updatedUrls.splice(index, 1)
            setFormData({
                ...formData,
                urls: updatedUrls,
            })
        } catch (error) {
            console.error(error)
        }
    }

    const handleSubmitComplete = async () => {
        if (validateForm()) {
            setLoading(true)
            try {
                const signupCompleteProject: SignupCompleteProjectModel = {
                    domain: formData.domain,
                    name: formData.name,
                    urls: formData.myUrls
                }
                const signupComplete: SignupCompleteModel = {
                    project: signupCompleteProject,
                    urls: formData.urls
                }
                await registerComplete(signupComplete)
                setLoading(false)
                setSuccess(true)
                // If successful, reset formErrors
                setFormErrors({})
                window.location.href = '/'
            } catch (error) {
                handleFormError('serverError', HandleApiError(intl, error));
                setLoading(false)
                setSuccess(false)
            }
        } else {
            console.log('Form has errors. Please fix them.');
        }
        console.log('Form submitted with data:', formData);
    };

    const validateForm = () => {

        const errors: Record<string, string> = {};

        if (!formData.name) {
            errors.projectName = 'Per favore inserisci un valore';
        }

        if (!formData.domain) {
            errors.projectDomain = 'Per favore inserisci un valore';
        }

        if (formData.myUrls.length === 0) {
            errors.myUrls = 'Per favore inserisci almeno un valore';
        }

        if (formData.urls.length === 0) {
            errors.urls = 'Per favore inserisci almeno un valore';
        }

        setFormErrors(errors);

        return Object.keys(errors).length === 0;
    };

    return (
        <div className="d-flex flex-column flex-column-fluid bg-light">

            <div id="kt_app_content" className="flex-column-fluid" data-kt-app-page-loading-enabled="true" data-kt-app-page-loading={loading ? 'on' : 'off'}>
                <div className="page-loader flex-column bg-dark bg-opacity-25">
                    <span className="spinner-border text-primary" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </span>
                </div>

                {/* {errors?.serverError && (
                    <div className='fv-plugins-message-container'>
                        <div data-field='appname' data-validator='notEmpty' className='fv-help-block'>
                            {errors?.serverError}
                        </div>
                    </div>
                )} */}

                <div
                    className='d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed'
                    style={{ backgroundColor: `#F2F2F2` }}>

                    <div className='d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20'>


                        <div className='col-xl-6 mb-xl-10 bg-body rounded shadow-sm p-10 p-lg-15 mx-auto'>
                            <div className='w-100 text-start'>

                                <div className="mx-auto mw-auto w-100 fv-plugins-bootstrap5 fv-plugins-framework">
                                    <div className="w-100">
                                        <div className="mb-13">
                                            <h2 className="mb-1 text-primary">Solo un ultimo sforzo {user?.name}!</h2>
                                            <div className="text-muted fw-semibold fs-5">
                                                Adesso che hai completato l'ordine, ti chiediamo di fornire alcune informazioni,
                                                in modo che la nostra AI possa configurare tuo pannello di controllo .
                                                Tutti i dati possono essere modificati in un secondo momento.
                                            </div>
                                        </div>

                                        {/* ------------------------------------------ */}

                                        <div className="fv-row mb-8 fv-plugins-icon-container fv-plugins-bootstrap5-row-valid">
                                            <label className="required fs-6 fw-semibold mb-2">Sito web del progetto
                                                <span className="text-muted fw-semibold fs-8 ms-2">(Es. www.mycompany.com)</span>
                                            </label>
                                            <input type="text" className="form-control form-control-solid"
                                                placeholder="Inseriesci il sito web del tuo progetto"
                                                name="url_desc"
                                                value={formData.domain}

                                                onChange={(value) => {
                                                    const url = value.target.value;
                                                    setFormData({ ...formData, domain: url })
                                                    if (!url) {
                                                        handleFormError('projectDomain', 'Per favore inserisci un valore');
                                                    } else {
                                                        handleFormError('projectDomain', '');
                                                    }

                                                    // Automatically update the project name if the URL is of the form "www.mycompany.com"
                                                    const domainMatch = url.match(/^(?:https?:\/\/)?(?:www\.)?([^\/]+)/);
                                                    if (domainMatch && domainMatch[1]) {
                                                        setFormData({ ...formData, domain: url, name: domainMatch[1] });
                                                        handleFormError('projectName', ''); // Clear any error if the name is automatically filled
                                                    }
                                                    if (!url) {
                                                        handleFormError('projectDomain', 'Per favore inserisci un valore');
                                                    } else {
                                                        handleFormError('projectDomain', '');
                                                    }
                                                }}
                                            />
                                            {formErrors?.projectDomain && (
                                                <div className='fv-plugins-message-container'>
                                                    <div data-field='appname' data-validator='notEmpty' className='fv-help-block'>
                                                        {formErrors?.projectDomain}
                                                    </div>
                                                </div>
                                            )}
                                        </div>

                                        <div className="fv-row mb-8 fv-plugins-icon-container fv-plugins-bootstrap5-row-valid">
                                            <label className="required fs-6 fw-semibold mb-2">Nome del progetto
                                                <span className="text-muted fw-semibold fs-8 ms-2">(Es. mycompany.com)</span>
                                            </label>
                                            <input type="text" className="form-control form-control-solid"
                                                placeholder="Inseriesci il nome del tuo progetto"
                                                name="url_desc"
                                                value={formData.name}
                                                onChange={(value) => {
                                                    setFormData({ ...formData, name: value.target.value })
                                                    if (!value.target.value) {
                                                        handleFormError('projectName', 'Per favore inserisci un valore');
                                                    } else {
                                                        handleFormError('projectName', '');
                                                    }
                                                }}
                                            />
                                            {formErrors?.projectName && (
                                                <div className='fv-plugins-message-container'>
                                                    <div data-field='appname' data-validator='notEmpty' className='fv-help-block'>
                                                        {formErrors?.projectName}
                                                    </div>
                                                </div>
                                            )}
                                        </div>

                                        {/* ------------------------------------------------------------------------ */}

                                        <div className="card card-flush bg-light">
                                            <div className="card-header pt-7">
                                                <h3 className="card-title align-items-start flex-column">
                                                    <span className="card-label fw-bold">I miei URL</span>

                                                </h3>
                                                <div className="card-toolbar">
                                                    <button type="button" className="btn btn-sm btn-light btn-success" data-bs-toggle="modal" data-bs-target={"#kt_modal_add_my_url"}>
                                                        <i className="bi bi-plus-lg fs-6"></i>Aggiungi</button>
                                                    <AddSignupMyUrlModal handleClose={function (urlModel: UrlModel): void {
                                                        var myUrls = formData.myUrls
                                                        myUrls.push(urlModel)
                                                        setFormData({ ...formData, myUrls: myUrls })
                                                    }} />
                                                </div>
                                            </div>
                                            <div className="card-body pt-0 pb-4">
                                                <div className="tab-content">
                                                    <div className="tab-pane fade active show" id="kt_stats_widget_6_tab_1">
                                                        <div className="table-responsive">
                                                            <table className="table table-row-dashed align-middle gs-0 gy-4 my-0">
                                                                <thead>
                                                                    <tr className="text-start fw-bold fs-7 text-uppercase gs-0">
                                                                        <th className="min-w-50px sorting">TIPO</th>
                                                                        <th className="min-w-125px sorting">CONTENUTO</th>
                                                                        <th className="text-end min-w-150px sorting_disabled">AZIONE</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {formData.myUrls.map((url, index) => {
                                                                        return (
                                                                            <tr>
                                                                                <td>
                                                                                    <span className="badge py-2 text-primary px-2 fs-9 badge-light-secondary">{url.typeUrl?.toUpperCase()}</span>
                                                                                </td>
                                                                                <td>
                                                                                    <span className="text-muted fw-semibold text-gray-800 d-block fs-7 mt-1">{url.description}</span>
                                                                                    <span className="text-muted fw-semibold d-block fs-7">{url.url}</span>
                                                                                </td>
                                                                                <td className="text-end">
                                                                                    <Button className="btn btn-sm btn-icon btn-delete btn-light-danger me-2"
                                                                                        onClick={() => { handleDeleteMyUrlsButtonClick(index) }}>
                                                                                        <i className="fa fa-trash-can fa-regular">
                                                                                        </i>
                                                                                    </Button>
                                                                                </td>
                                                                            </tr>
                                                                        )
                                                                    })}
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {formErrors?.myUrls && (
                                            <div className='fv-plugins-message-container'>
                                                <div data-field='appname' data-validator='notEmpty' className='fv-help-block'>
                                                    {formErrors?.myUrls}
                                                </div>
                                            </div>
                                        )}

                                        <div className="card card-flush mt-10 bg-light">
                                            <div className="card-header pt-7">
                                                <h3 className="card-title align-items-start flex-column">
                                                    <span className="card-label fw-bold text-gray-800">URL Competitors</span>
                                                    {user && user.role !== 'admin' && (
                                                        <span className="text-gray-500 mt-1 fw-semibold fs-6">
                                                            Hai aggiunto {formData.urls?.length} URL su {user?.plan?.urls_max}
                                                        </span>
                                                    )}
                                                </h3>
                                                <div className="card-toolbar">
                                                    <button type='button' className='btn btn-sm btn-success'
                                                        disabled={user && user.role !== 'admin' && formData.urls?.length >= user?.plan?.urls_max}
                                                        data-bs-toggle="modal" data-bs-target={"#kt_modal_add_url"}>
                                                        <i className="bi bi-plus-lg fs-6"></i>Aggiungi</button>
                                                    <AddSignupUrlModal
                                                        handleClose={function (urlModel: UrlModel): void {
                                                            var urls = formData.urls
                                                            urls.push(urlModel)
                                                            setFormData({ ...formData, urls: urls })
                                                        }} />
                                                </div>
                                            </div>
                                            <div className="card-body pt-0 my-5">
                                                <div className="tab-content">
                                                    <div className="tab-pane fade active show" id="kt_stats_widget_6_tab_1">
                                                        <div className="table-responsive">
                                                            <table className="table table-row-dashed align-middle gs-0 gy-4 my-0">
                                                                <thead>
                                                                    <tr className="text-start fw-bold fs-7 text-uppercase gs-0">
                                                                        <th className="min-w-50px sorting">TIPO</th>
                                                                        <th className="min-w-100px sorting">CONTENUTO</th>
                                                                        <th className="text-end min-w-150px sorting_disabled">AZIONE</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {formData.urls.map((url, index) => {
                                                                        return (
                                                                            <tr>
                                                                                <td>
                                                                                    <span className="badge py-2 text-primary px-2 fs-9 badge-light-secondary">{url.typeUrl?.toUpperCase()}</span>
                                                                                </td>
                                                                                <td>
                                                                                    <span className="text-muted fw-semibold text-gray-800 d-block fs-7 mt-1">{url.description}</span>
                                                                                    <span className="text-muted fw-semibold d-block fs-7">{url.url}</span>
                                                                                </td>
                                                                                <td className="text-end">
                                                                                    <Button className="btn btn-sm btn-icon btn-delete btn-light-danger me-2"
                                                                                        onClick={() => { handleDeleteUrlsButtonClick(index) }}>
                                                                                        <i className="fa fa-trash-can fa-regular">
                                                                                        </i>
                                                                                    </Button>
                                                                                </td>
                                                                            </tr>
                                                                        )
                                                                    })}
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {formErrors?.urls && (
                                            <div className='fv-plugins-message-container'>
                                                <div data-field='appname' data-validator='notEmpty' className='fv-help-block'>
                                                    {formErrors?.urls}
                                                </div>
                                            </div>
                                        )}


                                        {formErrors?.serverError && (
                                            <div className='fv-plugins-message-container mb-4 ms-1'>
                                                <div data-field='appname' data-validator='notEmpty' className='fv-help-block'>
                                                    {formErrors?.serverError}
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                    <div className="modal-footer mt-10">
                                        <div className='d-flex flex-stack'>
                                            <button type="submit" className="btn btn-lg btn-primary mt-4" onClick={() => { handleSubmitComplete() }}>
                                                {!loading && <span className='indicator-label'>Completa registrazione</span>}
                                                {loading && (
                                                    <span className='indicator-progress' style={{ display: 'block' }}>
                                                        Salvataggio in corso...
                                                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                                    </span>
                                                )}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export { SignupCompletePage }
