import React, { useRef, useState } from 'react';
import { useEffect } from 'react';
import { KTIcon, toAbsoluteUrl } from '../../../_metronic/helpers';
import { StepperComponent } from '../../../_metronic/assets/ts/components';
import { ICreateSignupData, defaultCreateSignupData } from './ISignupModels';
import { Step1 } from './signup-steps/Step1';
import { Step2 } from './signup-steps/Step2';
import { createPortal } from 'react-dom';
import { buySubscription, register } from './core/_requests';
import { HandleApiError } from '../../core/helpers/errorHandling';
import { useIntl } from 'react-intl';
import { useAuth } from './core/Auth';



const SignupPage = () => {

    const intl = useIntl()
    const [loading, setLoading] = useState(false)
    const [errors, setErrors] = useState<Record<string, string>>();
    const handleErrors = (field: string, errorMessage: string) => {
        setErrors((prevErrors) => ({
            ...prevErrors,
            [field]: errorMessage,
        }));
    };

    const { saveAuth, setCurrentUser } = useAuth()

    const modalsRoot = document.getElementById('root-modals') || document.body

    const stepperRef = useRef<HTMLDivElement | null>(null)
    const stepper = useRef<StepperComponent | null>(null)
    const [data, setData] = useState<ICreateSignupData>(defaultCreateSignupData)
    const [hasError, setHasError] = useState(false)

    useEffect(() => {
        if (stepperRef.current) {
            stepper.current = StepperComponent.createInsance(stepperRef.current as HTMLDivElement);
        }
    }, []);

    const updateData = (fieldsToUpdate: Partial<ICreateSignupData>) => {
        const updatedData = { ...data, ...fieldsToUpdate }
        setData(updatedData)
    }

    const validateItalianVAT = (vatNumber: string) => {
        console.log("Validating VAT number:", vatNumber); // Logging for debugging
        const regex = /^\d{11}$/;
        const isValid = regex.test(vatNumber);
        console.log("Is VAT number valid?", isValid); // Logging for debugging
        return isValid;
    }

    const checkAppBasic = (): boolean => {
        if (!data.appBasic.firstname || !data.appBasic.surname || !data.appBasic.email
            || !data.appBasic.password || (data.appBasic.password !== data.appBasic.passwordConfirm)
            || !data.appBasic.phone || !data.appBasic.company || !data.appBasic.vatNumber
            || !data.appBasic.address || !data.appBasic.zipCode || !data.appBasic.city || !data.appBasic.country
        ) {
            return false
        }
        if (validateItalianVAT(data.appBasic.vatNumber!) === false) {
            return false
        }
        return true
    }

    const checkAppPlan = (): boolean => {
        if (!data.appPlan.stripe_monthly_id || !data.appPlan.bmix_plan_id) {
            return false
        }

        return true
    }

    const prevStep = () => {
        if (!stepper.current) {
            return
        }

        stepper.current.goPrev()
    }

    const nextStep = async () => {
        setHasError(false)
        if (!stepper.current) {
            return
        }

        if (stepper.current.getCurrentStepIndex() === 1) {
            if (!checkAppBasic()) {
                setHasError(true)
                return
            }
            setLoading(true)
            try {
                const { data: auth } = await register(data.appBasic.firstname, data.appBasic.surname, data.appBasic.email, data.appBasic.phone, data.appBasic.password,
                    data.appBasic.company, data.appBasic.vatNumber, data.appBasic.address, data.appBasic.city,
                    data.appBasic.zipCode, data.appBasic.country,
                )
                saveAuth(auth)
                setErrors(undefined)
                setLoading(false)
            } catch (error) {
                console.error(error)
                handleErrors('serverError', HandleApiError(intl, error));
                setLoading(false)
                setHasError(true)
                return
            }
        }

        stepper.current.goNext()
    }

    const submit = async () => {
        if (!checkAppPlan()) {
            setHasError(true)
            return
        }
        setLoading(true)
        try {
            const result = await buySubscription(data.appPlan.stripe_monthly_id, data.appPlan.bmix_plan_id)
            console.log(result.data.sessionUrl)
            setErrors(undefined)
            setLoading(false)
            window.location.href = result.data.sessionUrl
        } catch (error) {
            console.error(error)
            handleErrors('serverError', HandleApiError(intl, error));
            setLoading(false)
            setHasError(true)
            return
        }
    }

    return createPortal(
        <div id="kt_modal_create_app">
            <div className="d-flex flex-column flex-root" id="kt_app_root">
                <div className="d-flex flex-column flex-lg-row flex-column-fluid stepper stepper-pills stepper-column stepper-multistep" id="kt_create_account_stepper">

                    <div className="d-flex flex-column flex-lg-row-fluid py-0">
                        <div className='d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed'
                            style={{ backgroundColor: `#F2F2F2` }} >
                            {/* begin::Content */}
                            <div className='d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20'>
                                {/* begin::Logo */}
                                <a href='#' className='mb-12'>
                                    <img
                                        alt='Logo'
                                        src={toAbsoluteUrl('/media/logos/brainmix-logo.png')}
                                        className='theme-dark-show h-55px'
                                    />
                                    <img
                                        alt='Logo'
                                        src={toAbsoluteUrl('/media/logos/brainmix-logo.png')}
                                        className='theme-light-show h-55px'
                                    ></img>
                                </a>
                                {/* end::Logo */}
                                {/* begin::Wrapper */}
                                <div className='bg-body rounded shadow-sm p-10 p-lg-15 mx-auto col-xl-12 mb-5 mb-xl-10'>
                                    <div
                                        ref={stepperRef}
                                        className='stepper stepper-pills stepper-row d-flex flex-column flex-xl-column flex-column-fluid'
                                        id='kt_modal_create_app_stepper'>

                                        {/* begin::Aside*/}
                                        <div className='d-flex justify-content-center justify-content-xl-start'>
                                            {/* begin::Nav*/}
                                            <div className='stepper-nav ps-lg-10'>
                                                {/* begin::Step 1*/}
                                                <div className='stepper-item me-5 me-md-15 current' data-kt-stepper-element='nav'>
                                                    {/* begin::Wrapper*/}
                                                    <div className='stepper-wrapper'>
                                                        {/* begin::Icon*/}
                                                        <div className='stepper-icon w-40px h-40px'>
                                                            <i className='stepper-check fas fa-check'></i>
                                                            <span className='stepper-number'>1</span>
                                                        </div>
                                                        {/* end::Icon*/}

                                                        {/* begin::Label*/}
                                                        <div className='stepper-label'>
                                                            <h3 className='stepper-title'>Account</h3>

                                                            <div className='stepper-desc'>Crea account</div>
                                                        </div>
                                                        {/* end::Label*/}
                                                    </div>
                                                    {/* end::Wrapper*/}

                                                </div>
                                                {/* end::Step 1*/}

                                                {/* begin::Step 2*/}
                                                <div className='stepper-item me-5 me-md-15' data-kt-stepper-element='nav'>
                                                    {/* begin::Wrapper*/}
                                                    <div className='stepper-wrapper'>
                                                        {/* begin::Icon*/}
                                                        <div className='stepper-icon w-40px h-40px'>
                                                            <i className='stepper-check fas fa-check'></i>
                                                            <span className='stepper-number'>2</span>
                                                        </div>
                                                        {/* begin::Icon*/}

                                                        {/* begin::Label*/}
                                                        <div className='stepper-label'>
                                                            <h3 className='stepper-title'>Piano</h3>

                                                            <div className='stepper-desc'>Seleziona piano</div>
                                                        </div>
                                                        {/* begin::Label*/}
                                                    </div>
                                                    {/* end::Wrapper*/}
                                                </div>
                                                {/* end::Step 2*/}
                                            </div>
                                            {/* end::Nav*/}
                                        </div>
                                        {/* begin::Aside*/}

                                        {/*begin::Content */}
                                        <div className='flex-row-fluid py-lg-5 px-lg-15'>
                                            {/*begin::Form */}
                                            <form noValidate id='kt_modal_create_app_form'>
                                                <Step1 data={data} updateData={updateData} hasError={hasError} />
                                                <Step2 data={data} updateData={updateData} hasError={hasError} />

                                                {errors?.serverError && (
                                                    <div className='fv-plugins-message-container'>
                                                        <div data-field='appname' data-validator='notEmpty' className='fv-help-block'>
                                                            {errors?.serverError}
                                                        </div>
                                                    </div>
                                                )}

                                                {/*begin::Actions */}
                                                <div className='d-flex flex-stack pt-10'>
                                                    <div className='me-'>
                                                        {/* <button
                                                            type='button'
                                                            className='btn btn-lg btn-light-primary me-3'
                                                            data-kt-stepper-action='previous'
                                                            onClick={prevStep}
                                                        >
                                                            <KTIcon iconName='arrow-left' className='fs-3 me-1' /> Indietro
                                                        </button> */}
                                                    </div>
                                                    <div>
                                                        <button
                                                            type='button'
                                                            className='btn btn-lg btn-primary'
                                                            data-kt-stepper-action='submit'
                                                            onClick={submit}
                                                            disabled={loading}
                                                        >
                                                            {!loading && <span className='indicator-label'>Iscriviti</span>}
                                                            {loading && (
                                                                <span className='indicator-progress' style={{ display: 'block' }}>
                                                                    Iscrizione in corso...
                                                                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                                                </span>
                                                            )}
                                                            <KTIcon iconName='arrow-right' className='fs-3 ms-1 me-0' />
                                                        </button>

                                                        <button
                                                            type='button'
                                                            className='btn btn-lg btn-primary'
                                                            data-kt-stepper-action='next'
                                                            onClick={nextStep}
                                                            disabled={loading}
                                                        >
                                                            {!loading && <span className='indicator-label'>Registrati</span>}
                                                            {loading && (
                                                                <span className='indicator-progress' style={{ display: 'block' }}>
                                                                    Registrazione in corso...
                                                                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                                                </span>
                                                            )}
                                                            <KTIcon iconName='arrow-right' className='fs-3 ms-1 me-0' />
                                                        </button>
                                                    </div>
                                                </div>
                                                {/*end::Actions */}
                                            </form>
                                            {/*end::Form */}
                                        </div>
                                        {/*end::Content */}
                                    </div>
                                </div>
                                {/* end::Wrapper */}
                            </div>
                        </div>
                    </div>
                    <div className="d-flex flex-column flex-lg-row-auto w-lg-350px w-xl-350px" style={{ backgroundColor: '#0091CF' }} >
                        <div className="d-flex flex-column position-lg-fixed top-0 bottom-0 w-lg-350px w-xl-350px bg-color-primary scroll-y bgi-size-contain bgi-no-repeat bgi-position-bottom" style={{ backgroundImage: `url(${toAbsoluteUrl('/media/misc/signup-right-bg.png')})` }}>
                        </div>
                    </div>
                </div>
            </div>
        </div>,
        modalsRoot
    )
};

export { SignupPage };
