/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react'
import { useParams } from "react-router-dom";
import { getAnalysisEventSuggestion } from './core/_requests'
import SidebarDrawer from "../main/SidebarDrawer/SidebarDrawer";

const Suggestion: React.FC = () => {

  let { eventId } = useParams();

  const [suggestion, setSuggestion] = useState<any | null>(null)

  const [date, setDate] = useState(new Date());

  useEffect(() => {
    getAnalysisEventSuggestion(eventId).then((res) => {
      setSuggestion(res.data)
      setDate(new Date(res.data.eventId?.eventDate))
    });
  }, []);

  return (
    <>
      <div className="d-flex flex-column flex-column-fluid bg-light">

        <div id="kt_app_toolbar" className="app-toolbar  py-4 py-lg-8 ">

          <div id="kt_app_toolbar_container" className="app-container  container-fluid d-flex flex-stack flex-wrap ">
            <div className="app-toolbar-wrapper d-flex flex-stack flex-wrap gap-4 w-100">
              <div className="page-title d-flex flex-column justify-content-center gap-1 me-3">
                <h1 className="text-hover-primary fs-2 fw-bold me-3">
                  Consigli
                </h1>
              </div>
            </div>
          </div>
        </div>

        <div id="kt_app_content" className="flex-column-fluid">
          <div className="d-flex">
            <div id="kt_app_content_container" className="app-container container-fluid">
              <div className="d-flex flex-row flex-column-fluid">
                <div className="vh-75 w-100">
                  <div className="row gy-5 g-xl-10">
                    <div className="col-xl-12 mb-xl-10">
                      <div className="card card-flush mb-4">
                        <div className="card-header pt-7">
                          <h3 className="card-title align-items-start flex-column">
                            <span className="card-label fw-bold text-gray-800">Ho individuato questa attività</span>
                          </h3>
                          <div className="card-toolbar">
                            {/* <a href="#" className="btn btn-sm btn-light">Add Product</a> */}
                          </div>
                        </div>
                        <div className="card-body pt-0 my-2">
                          <div className="d-flex flex-wrap flex-sm-nowrap">
                            <div className='flex-grow-1'>
                              <div className="d-flex flex-column">

                                <div className="alert alert-secondary d-flex align-items-center p-5 mb-5">
                                  <span className="svg-icon svg-icon-2hx svg-icon-secondary me-3">
                                    <i className="bi bi-diagram-3 fs-2x me-4"></i>
                                  </span>
                                  <div className="d-flex flex-column">
                                    <h5 className="mb-1">{suggestion?.competitor?.brandName}</h5>
                                    <div className="fs-6 text-gray-700 pe-7">{suggestion?.competitor?.domain}</div>
                                  </div>
                                </div>
                              </div>

                              <div className="d-flex flex-wrap mb-4">
                                <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                                  <div className="d-flex align-items-center">
                                    <div className="fs-4 fw-bold">{Intl.DateTimeFormat("it-IT", { year: "numeric", day: "2-digit", month: "long" }).format(date)}</div>
                                  </div>
                                  <div className="fw-semibold fs-6 text-gray-400">Data evento</div>
                                </div>
                                <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                                  <div className="d-flex align-items-center">
                                    <div className="fs-4 fw-bold">{suggestion?.eventId?.eventType}</div>
                                  </div>
                                  <div className="fw-semibold fs-6 text-gray-400">Tipo evento</div>
                                </div>
                              </div>

                              <div className="d-flex flex-column">
                                <h5 className="fs-7 fw-bold mb-1">Info</h5>
                                <div className="fs-6 text-gray-700 pe-7">{suggestion?.eventId?.eventInfo}</div>
                              </div>

                            </div>


                          </div>
                        </div>
                      </div>

                      <div className="card card-flush">
                        <div className="card-header pt-7">
                          <h3 className="card-title align-items-start flex-column">
                            <span className="card-label fw-bold text-gray-800">Il mio consiglio</span>
                          </h3>
                        </div>
                        <div className="card-body pt-0 my-2">
                          <div className="d-flex flex-wrap flex-sm-nowrap">
                            <div className='flex-grow-1'>
                              <div className="d-flex flex-column">
                                <div className="fs-6 text-gray-700 pe-7">{suggestion?.suggestion}</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
            <SidebarDrawer />
          </div>
        </div>
      </div>
    </>
  );
};

export default Suggestion;
