import React, { useState, useEffect } from 'react'
import { ProductModel } from '../core/_models'
import { getUserByToken } from '../../../modules/auth/core/_requests'
import { addProduct, editProduct } from '../core/_requests'
import { UserModel } from '../../../modules/auth'
import { HandleApiErrors } from '../../../core/helpers/errorHandling'
import { useIntl } from 'react-intl'

type Props = {
  product: ProductModel
  index: number
  handleClose: () => void
}

interface FormState {
  nameValue: string
  urlValue: string
}

const EditProductModal: React.FC<Props> = ({ product, index, handleClose }) => {

  const intl = useIntl()
  const [user, setUser] = useState<UserModel>()
  const [formErrors, setFormErrors] = useState<Record<string, string>>();
  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState(false)

  useEffect(() => {
    getUserByToken().then((res) => {
      setUser(res.data.user)
      setFormData({ ...formData })
    });
    formData.nameValue = product.productName
    formData.urlValue = product.productUrl
  }, []);

  const [formData, setFormData] = useState<FormState>({
    nameValue: '',
    urlValue: 'https://'
  });

  const handleFormError = (field: string, errorMessage: string) => {
    setFormErrors((prevErrors) => ({
      ...prevErrors,
      [field]: errorMessage,
    }));
  };

  const validateForm = () => {
    const errors: Record<string, string> = {};

    if (!formData.nameValue) {
      errors.urlDescription = 'Per favore inserisci un valore';
    }

    if (!formData.urlValue) {
      errors.urlValue = 'Per favore inserisci un valore';
    }

    setFormErrors(errors);

    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (validateForm()) {
      setLoading(true)
      try {
        await editProduct(product._id, formData.nameValue, formData.urlValue, product.analyze)
        setLoading(false)
        setSuccess(true)
        // If successful, reset formErrors
        setFormErrors({})
      } catch (error) {
        handleFormError('serverError', HandleApiErrors(intl, error));
        setLoading(false)
        setSuccess(false)
      }
    } else {
      console.log('Form has errors. Please fix them.');
    }
    console.log('Form submitted with data:', formData);
  };


  return <div className="modal fade" tabIndex={-1} id={"kt_modal_edit_product_" + index}>
    <div className="modal-dialog modal-dialog-centered mw-600px">

      {/* FORM MODAL */}
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title">Modifica prodotto</h5>
          <div
            className="btn btn-icon btn-sm btn-active-light-primary ms-2"
            data-bs-dismiss="modal"
            aria-label="Close">
            <i className="bi bi-x-lg fs-4"></i>
          </div>
        </div>
        {!success && <div className="modal-body text-start">
          <form onSubmit={handleSubmit} className="mx-auto mw-500px w-100 pt-5 pb-0 fv-plugins-bootstrap5 fv-plugins-framework" id="kt_modal_edit_product_form">
            <div className="w-100">
              <div className="mb-13">
                <h2 className="mb-1">Modifica prodotto</h2>
                <div className="text-muted fw-semibold fs-5">
                  Modifica il prodotto da analizzare e comparare con i prodotti dei competitors
                </div>
              </div>

              <div className="fv-row mb-8 fv-plugins-icon-container fv-plugins-bootstrap5-row-valid">
                <label className="required fs-6 fw-semibold mb-2">Nome prodotto
                  <span className="text-muted fw-semibold fs-8 ms-2">(Es. Scarpe da donna)</span>
                </label>
                <input type="text" className="form-control form-control-solid"
                  placeholder="Inseriesci nome prodotto..." name="product_name"
                  value={formData.nameValue}
                  onChange={(value) => {
                    setFormData({ ...formData, nameValue: value.target.value })
                    if (!value.target.value) {
                      handleFormError('nameValue', 'Per favore inserisci un valore');
                    } else {
                      handleFormError('nameValue', '');
                    }
                  }}
                />
                {formErrors?.urlDescription && (
                  <div className='fv-plugins-message-container'>
                    <div data-field='appname' data-validator='notEmpty' className='fv-help-block'>
                      {formErrors?.urlDescription}
                    </div>
                  </div>
                )}
              </div>

              <div className="fv-row mb-8 fv-plugins-icon-container fv-plugins-bootstrap5-row-valid">
                <label className="required fs-6 fw-semibold mb-2">URL del Prodotto
                  <span className="text-muted fw-semibold fs-8 ms-2">(Es. https://www.....)</span>
                </label>
                <input type="text" className="form-control form-control-solid"
                  placeholder="Inseriesci un URL valido ..."
                  name="url_title"
                  value={formData.urlValue}
                  onChange={(value) => {
                    setFormData({ ...formData, urlValue: value.target.value })
                    if (!value.target.value) {
                      handleFormError('urlValue', 'Per favore inserisci un valore');
                    } else {
                      handleFormError('urlValue', '');
                    }
                  }}
                />
                {formErrors?.urlValue && (
                  <div className='fv-plugins-message-container'>
                    <div data-field='appname' data-validator='notEmpty' className='fv-help-block'>
                      {formErrors?.urlValue}
                    </div>
                  </div>
                )}
              </div>

              {formErrors?.serverError && (
                <div className='fv-plugins-message-container mb-4 ms-1'>
                  <div data-field='appname' data-validator='notEmpty' className='fv-help-block'>
                    {formErrors?.serverError}
                  </div>
                </div>
              )}
            </div>
            <div className="modal-footer mb-0">
              <div className='d-flex flex-stack'>
                <button type="button" className="btn btn-lg btn-light me-3" data-bs-dismiss="modal">Chiudi</button>
                <button type="submit" className="btn btn-lg btn-primary">
                  {!loading && <span className='indicator-label'>Conferma</span>}
                  {loading && (
                    <span className='indicator-progress' style={{ display: 'block' }}>
                      Salvataggio in corso...
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  )}
                </button>
              </div>
            </div>
          </form>
        </div>}
        {/* END FORM MODAL */}
        {/* SUCCESS */}
        {success && <div className='modal-body'>
          <div className="w-100">
            <div className="mb-8">
              <div className="text-center px-4">
                <img src="/media/illustrations/sketchy-1/20.png" alt="" className="mw-100 mh-200px mb-8" />
              </div>
              <h2 className="mb-3">Successo!</h2>
              <div className="text-muted fw-semibold fs-5">
                Prodotto modificato con successo. Fai click sull'icona con il cuore per attivare la scansione e la comparazione con i prodotti competitor
              </div>
            </div>
            <div className="modal-footer pt-4 pb-0 mb-0">
              <div className='d-flex flex-stack'>
                <button onClick={handleClose} type="button" className="btn btn-lg btn-light" data-bs-dismiss="modal">Chiudi</button>
              </div>
            </div>
          </div>
        </div>}
        {/* END SUCCESS */}
      </div>
    </div >
  </div >
}

export { EditProductModal }
