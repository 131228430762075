export interface ISignupBasic {
  firstname?: string
  surname?: string
  email?: string
  password?: string
  passwordConfirm?: string
  phone?: string
  company?: string
  vatNumber?: string
  address?: string
  city?: string
  zipCode?: string
  country?: string
}

export type TAppPlan = {
  bmix_plan_id?: string
  stripe_monthly_id?: string
}

export interface ICreateSignupData {
  appBasic: ISignupBasic
  appPlan: TAppPlan
}

export const defaultCreateSignupData: ICreateSignupData = {
  appBasic: { country: 'IT' },
  appPlan: {},
}

export type StepProps = {
  data: ICreateSignupData
  updateData: (fieldsToUpdate: Partial<ICreateSignupData>) => void
  hasError: boolean
}
