import { AxiosError } from 'axios';
import { ErrorResponse } from '../models/errorModel'

export function HandleApiErrors(intl: any, error: AxiosError<ErrorResponse> | any): string {

  try {
    console.log(error)
    if (error.response && error.response.data && error.response.data.errors) {
      const { msg } = error.response.data.errors;
      const translatedError = intl.formatMessage({ id: 'ERRORS.' + msg })
      return translatedError;
    } else {
      // Handle other types of errors (network, etc.)
      return intl.formatMessage({ id: 'ERRORS.GENERIC' });
    }
  } catch (e) {
    return intl.formatMessage({ id: 'ERRORS.GENERIC' });
  }
}

export function HandleApiError(intl: any, error: AxiosError<ErrorResponse> | any): string {

  try {
    console.log(error)
    if (error.response && error.response.data && error.response.data.errors) {
      const { msg } = error.response.data.errors;
      const translatedError = intl.formatMessage({ id: 'ERRORS.' + msg })
      return translatedError;
    } else {
      // Handle other types of errors (network, etc.)
      return intl.formatMessage({ id: 'ERRORS.GENERIC' });
    }
  } catch (e) {
    return intl.formatMessage({ id: 'ERRORS.GENERIC' });
  }
}