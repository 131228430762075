import Dashboard from "./Dashboard";

const DashboardWrapper = () => {
  return (
    <>
      <div className="app-wrapper flex-row flex-row-fluid">
        <div className="app-main flex-column flex-row-fluid" id="kt_app_main">
          <Dashboard />
        </div>
      </div>
    </>
  );
};

export { DashboardWrapper };
