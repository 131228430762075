import axios from 'axios'
import { PaginationModel } from '../../../core/models/wrapperModels'
import { ProductModel, } from './_models'

const API_URL = process.env.REACT_APP_API_URL

export const GET_MINE_PRODUCTS_URL = `${API_URL}/products/mine`
export const GET_SUGGESTED_PRODUCTS_URL = `${API_URL}/products/suggested`
export const GET_PRODUCT_DETAILS_URL = `${API_URL}/products/detail/`
export const POST_ADD_PRODUCT_URL = `${API_URL}/products/add`
export const POST_ADD_COMPETITOR_PRODUCT_URL = `${API_URL}/products/competitor/add/`
export const PRODUCTS_URL = `${API_URL}/products/`

export function getProducts(page: number, limit: number, analyze?: boolean) {
  return axios.get<PaginationModel<ProductModel>>(GET_MINE_PRODUCTS_URL, { params: { page: page, limit: limit, analyze: analyze } })
}

export function getSuggestedProducts(page: number, limit: number, analyze?: boolean) {
  return axios.get<ProductModel[]>(GET_SUGGESTED_PRODUCTS_URL, { params: { page: page, limit: limit, analyze: analyze } })
}

export function getProductDetails(productId?: string) {
  return axios.get<ProductModel>(GET_PRODUCT_DETAILS_URL + productId)
}

export function addProduct(name: string, url: string) {
  const formData = new URLSearchParams();
  formData.append('name', name);
  formData.append('url', url);
  formData.append('analyze', 'true');
  return axios.post<ProductModel>(POST_ADD_PRODUCT_URL, formData, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    }
  })
}

export function addCompetitorProduct(id: String, name: string, url: string, competitor: string) {
  const formData = new URLSearchParams();
  formData.append('name', name);
  formData.append('url', url);
  formData.append('competitor', competitor);
  formData.append('analyze', 'true');
  return axios.post<ProductModel>(POST_ADD_COMPETITOR_PRODUCT_URL + id, formData, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    }
  })
}

export function editProduct(id: String, name: string, url: string, analyze: boolean) {
  const formData = new URLSearchParams();
  formData.append('name', name);
  formData.append('url', url);
  formData.append('analyze', analyze.toString());
  return axios.patch<ProductModel>(PRODUCTS_URL + id, formData, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    }
  })
}

export function deleteProduct(id: String) {
  return axios.delete<ProductModel>(PRODUCTS_URL + id, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    }
  })
}