import React, { useState, useEffect } from 'react'
import { Link } from "react-router-dom"
import clsx from 'clsx'
import { KTIcon, toAbsoluteUrl } from '../../../helpers'
import { HeaderNotificationsMenu, HeaderUserMenu, QuickLinks, Search, ThemeModeSwitcher, } from '../../../partials'
import { MenuItem } from './MenuItem'
import { getUserByToken, useAuth } from '../../../../app/modules/auth'
import { updateSelectedProject } from '../../../../app/modules/profile/core/_requests'
import { Header } from './Header'
import { MainMenu } from './MainMenu'

const toolbarButtonMarginClass = 'ms-1 ms-lg-3',
  toolbarButtonHeightClass = 'btn-active-light-primary btn-custom w-30px h-30px w-md-40px h-md-40p',
  toolbarUserAvatarHeightClass = 'symbol-30px symbol-md-40px',
  toolbarButtonIconSizeClass = 'fs-1'

const Navbar: React.FC = () => {

  const { currentUser, saveAuth, setCurrentUser } = useAuth()

  const [selectedProject, setSelectedProject] = useState(currentUser?.selectedProject?._id || '');

  // Event handler for select change
  const handleProjectChange = async (project: any) => {
    const selectedProjectId = project.target.value;
    setSelectedProject(selectedProjectId);
    await updateSelectedProject(selectedProjectId)
    const { data: auth } = await getUserByToken()
    saveAuth(auth)
    setCurrentUser(auth.user)
    window.location.href = '/';
  };

  return (
    <div className="app-header ">
      <div className="app-container container-fluid d-flex align-items-stretch justify-content-between">
        {/* header logo*/}
        <div className="app-header-logo d-flex align-items-center ps-lg-2 me-lg-10">
          <button id="kt_header_menu_mobile_toggle" className="btn btn-icon btn-color-gray-500 btn-active-color-primary w-35px h-35px ms-n2 me-2 d-flex d-lg-none">
            <i className="ki-solid ki-abstract-14 fs-1"></i>
          </button>
          <Header />
          <Link to={"/analysis/signals"}>
            <img
              alt="Logo"
              src={toAbsoluteUrl("/media/logos/brainmix-logo.png")}
              className="h-30px"
            />
          </Link>
        </div>
        {/* end header logo*/}
        {/*begin::Menu wrapper*/}
        <div className="d-flex align-items-stretch justify-content-between flex-lg-grow-1">
          <div
            className="d-flex align-items-stretch"
            id="kt_app_header_menu_wrapper"
          >
            <div
              className="app-header-menu app-header-mobile-drawer align-items-stretch"
              data-kt-drawer="true"
              data-kt-drawer-name="app-header-menu"
              data-kt-drawer-activate="{default: true, lg: false}"
              data-kt-drawer-overlay="true"
              data-kt-drawer-width="{default:'200px', '300px': '250px'}"
              data-kt-drawer-direction="start"
              data-kt-drawer-toggle="#kt_app_header_menu_toggle"
              data-kt-swapper="true"
              data-kt-swapper-mode="prepend"
              data-kt-swapper-parent="{default: '#kt_app_body', lg: '#kt_app_header_menu_wrapper'}"
            >
              <div className="menu menu-rounded menu-column menu-lg-row menu-active-bg menu-title-gray-600 menu-state-dark menu-arrow-gray-400 fw-semibold fw-semibold fs-6 align-items-stretch my-5 my-lg-0 px-2 px-lg-0"
                id="#kt_app_header_menu"
                data-kt-menu="true">
                <MainMenu />
              </div>
            </div>
          </div>
        </div>
        {/* Start Menu Icons */}
        <div className="app-navbar flex-shrink-0">

          {/* Search
          <div className={clsx('d-flex align-items-stretch', toolbarButtonMarginClass)}>
            <Search />
          </div> */}

          {/* Project selection */}
          <div className={clsx('d-flex align-items-center d-none d-lg-flex', toolbarButtonMarginClass)}>
            <div className="form-floating">
              <select className="form-select form-select-solid" id="floatingSelect" aria-label="Seleziona progetto" onChange={handleProjectChange}>
                {currentUser?.followedProjects?.map((project, index) => {
                  return (
                    <option
                      key={project._id}
                      value={project._id}
                      selected={project._id === selectedProject}
                    >{project.brandName}</option>
                  )
                })}
              </select>
              <label>Seleziona progetto</label>
            </div>
          </div>

          {/* Project */}
          <div className='d-flex align-items-center ms-1 d-none d-lg-flex'>
            <Link to='/account/projects' >
              <div
                className={clsx(
                  'btn btn-icon btn-active-light-primary btn-custom',
                  toolbarButtonHeightClass
                )}
                id='kt_activities_toggle' >
                <KTIcon iconName='abstract-25' className={toolbarButtonIconSizeClass} />
              </div>
            </Link>
          </div>

          {/* Dashboard */}
          {/* <div className={clsx('d-flex align-items-center d-none d-lg-flex', toolbarButtonMarginClass)}>
            <Link to='/dashboard' >
              <div
                className={clsx(
                  'btn btn-icon btn-active-light-primary btn-custom',
                  toolbarButtonHeightClass
                )}
                id='kt_activities_toggle'
              >
                <KTIcon iconName='element-1' className={toolbarButtonIconSizeClass} />
              </div>
            </Link>
          </div> */}

          {/* NOTIFICATIONS */}
          {/* <div className={clsx('d-flex align-items-center d-none d-lg-flex', toolbarButtonMarginClass)}>
          
            <div
              className={clsx(
                'btn btn-icon btn-active-light-primary btn-custom',
                toolbarButtonHeightClass
              )}
              data-kt-menu-trigger='click'
              data-kt-menu-attach='parent'
              data-kt-menu-placement='bottom-end'
              data-kt-menu-flip='bottom'
            >
              <KTIcon iconName='notification' className={toolbarButtonIconSizeClass} />
            </div>
            <HeaderNotificationsMenu />
          </div> */}

          {/* begin::User */}
          <div
            className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}
            id='kt_header_user_menu_toggle'
          >
            {/* begin::Toggle */}
            <div
              className={clsx('cursor-pointer symbol', toolbarUserAvatarHeightClass)}
              data-kt-menu-trigger='click'
              data-kt-menu-attach='parent'
              data-kt-menu-placement='bottom-end'
              data-kt-menu-flip='bottom'
            >
              <img
                className='h-30px w-30px rounded'
                src={toAbsoluteUrl('/media/avatars/blank.png')}
                alt='metronic'
              />
            </div>
            <HeaderUserMenu />
            {/* end::Toggle */}
          </div>
          {/* end::User */}

        </div>

        {/*end::Menu wrapper*/}
      </div>
    </div>
  );
};

export { Navbar }