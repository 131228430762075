import { useState } from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import { Link, useParams } from 'react-router-dom'
import { useFormik } from 'formik'
import { resetPassword } from '../core/_requests'
import { useIntl } from 'react-intl'
import { HandleApiError, HandleApiErrors } from '../../../core/helpers/errorHandling'

const initialValues = {
  password: '',
  passwordConfirmation: ''
}

const forgotPasswordSchema = Yup.object().shape({
  password: Yup.string()
    .min(8, 'Minimo 8 caratteri')
    .max(50, 'Massimo 50 caratteri')
    .required('Password obbligatoria'),
  passwordConfirmation: Yup.string()
    .min(8, 'Minimo 8 caratteri')
    .max(50, 'Massimo 50 caratteri')
    .required('Password obbligatoria')
    .oneOf([Yup.ref('password')], 'Le password devono essere uguali'),
})
const ResetPassword: React.FC = () => {

  const intl = useIntl()

  let { verification } = useParams<{ verification?: string | undefined }>();

  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState(false)
  const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined)
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined)

  const formik = useFormik({
    initialValues,
    validationSchema: forgotPasswordSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setLoading(true)
      setHasErrors(undefined)
      setTimeout(() => {
        resetPassword(verification!, values.password)
          .then(({ data: { result } }) => {
            setHasErrors(false)
            setSubmitting(false)
            setLoading(false)
            setSuccess(true)
          })
          .catch((error) => {
            setHasErrors(true)
            setLoading(false)
            setSubmitting(false)
            setSuccess(false)
            setErrorMessage(HandleApiErrors(intl, error))
          })
      }, 1000)
    },
  })

  return (<div className='modal-body'>

    {success &&
      <div className="w-100">
        <div className="mb-8">
          <div className="text-center px-4">
            <img src="/media/illustrations/sketchy-1/20.png" alt="" className="mw-100 mh-200px mb-8" />
          </div>
          <h2 className="mb-3">Password impostata con successo!</h2>
          <div className="text-muted fw-semibold fs-5">
            La tua nuova password è pronta per essere utilizzata! Effettua nuovamente il login con la tua nuova password per accedere.
          </div>
        </div>
        <div className="modal-footer pt-4 pb-0 mb-0">
          <div className='d-flex flex-stack'>
            <Link to='/auth/login'>
              <button type="button" className="btn btn-lg btn-success" data-bs-dismiss="modal">Torna al Login</button>
            </Link>
          </div>
        </div>
      </div>}

    {!success && <form
      className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
      noValidate
      id='kt_login_password_reset_form'
      onSubmit={formik.handleSubmit}
    >
      <div className='text-center mb-10'>
        {/* begin::Title */}
        <h1 className='text-dark fw-bolder mb-3'>Nuova password</h1>
        {/* end::Title */}

        {/* begin::Link */}
        <div className='text-gray-500 fw-semibold fs-6'>
          Inserisci e conferma la tua nuova password.
        </div>
        {/* end::Link */}
      </div>

      {/* begin::Title */}
      {hasErrors === true && (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>
            {errorMessage ?? 'Ops! Sembra che qualcosta non sta funzionando! Per favore riprova.'}
          </div>
        </div>
      )}

      {hasErrors === false && (
        <div className='mb-10 bg-light-info p-8 rounded'>
          <div className='text-info'>Ti abbiamo inviato una richiesta di reset password. Per favore controlla tua email.</div>
        </div>
      )}
      {/* end::Title */}

      {/* begin::Form group */}
      <div className='fv-row mb-8'>
        <label className='form-label fw-bolder text-gray-900 fs-6'>Nuova password</label>
        <input
          type='password'
          placeholder='Scegli una nuova password'
          autoComplete='off'
          {...formik.getFieldProps('password')}
          className={clsx(
            'form-control bg-transparent',
            { 'is-invalid': formik.touched.password && formik.errors.password },
            {
              'is-valid': formik.touched.password && !formik.errors.password,
            }
          )}
        />
        {formik.touched.password && formik.errors.password && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.password}</span>
            </div>
          </div>
        )}
      </div>

      <div className='fv-row mb-8'>
        <label className='form-label fw-bolder text-gray-900 fs-6'>Conferma password</label>
        <input
          type='password'
          placeholder='Conferma la nuova password'
          autoComplete='off'
          {...formik.getFieldProps('passwordConfirmation')}
          className={clsx(
            'form-control bg-transparent',
            { 'is-invalid': formik.touched.passwordConfirmation && formik.errors.passwordConfirmation },
            {
              'is-valid': formik.touched.passwordConfirmation && !formik.errors.passwordConfirmation,
            }
          )}
        />
        {formik.touched.passwordConfirmation && formik.errors.passwordConfirmation && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.passwordConfirmation}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
        <button
          type='submit'
          id='kt_password_reset_submit'
          className='btn btn-primary me-4'
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {!loading && <span className='indicator-label'>Conferma</span>}
          {loading && (
            <span className='indicator-progress' style={{ display: 'block' }}>
              Richiesta in corso...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
        <Link to='/auth/login'>
          <button
            type='button'
            id='kt_login_password_reset_form_cancel_button'
            className='btn btn-light'
            disabled={formik.isSubmitting || !formik.isValid}
          >
            Annulla
          </button>
        </Link>
      </div>
      {/* end::Form group */}
    </form>}
  </div>)
};

export default ResetPassword;
