import React from "react";
import { toAbsoluteUrl } from "../../../../_metronic/helpers";

const SidebarDrawer = () => {
  return (

    <div className="hover-overlay-y-custom"
      data-kt-scroll="false"
      data-kt-scroll-activate="{default: false, lg: true}"
      data-kt-scroll-height="auto"
      data-kt-scroll-dependecies="#kt_app_header"
      data-kt-scroll-wrappers="#kt_app_content"
      data-kt-scroll-offset="5px"
      style={{ height: "277px" }}
    >
      <div className=" px-5 px-lg-10">
        <div className=" fade active show" id="kt_app_sidebar_secondary_collections" role="tabpanel" >
          <div className="card card-reset card-p-0">
            <div className="card-body d-flex flex-column flex-center">

              <div className="mb-2 text-center">

                <h4 className="fw-semibold text-gray-800 text-center lh-lg">Suggerimenti</h4>
                <span className="text-center">Prova i nostri suggerimenti che ti aiuterrano con il tuo progetto</span>

                <div className="py-10 text-center">
                  <img src="assets/media/svg/illustrations/easy/1.svg" className="theme-light-show w-200px" alt="" />
                  <img src="assets/media/svg/illustrations/easy/1-dark.svg" className="theme-dark-show w-200" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SidebarDrawer;
