import React, { useState, useEffect, useRef } from 'react'
import { getUserByToken } from '../core/_requests'
import { UserModel } from '..'
import { HandleApiErrors } from '../../../core/helpers/errorHandling'
import { useIntl } from 'react-intl'
import Select, { MultiValue } from 'react-select'
import { UrlModel } from '../../../pages/analysis/core/_models'
import { getRequestTypes } from '../../../pages/analysis/core/_requests'

type Props = {
  handleClose: (urlModel: UrlModel) => void;
}

interface FormState {
  urlType: string
  urlValue: string
  urlDescription: string
  requestTypes: string[]
}

const AddSignupUrlModal: React.FC<Props> = ({ handleClose }) => {

  const intl = useIntl()
  const [user, setUser] = useState<UserModel>()
  const [requestTypesOptions, setRequestTypesOptions] = useState<any[]>([])
  const [formErrors, setFormErrors] = useState<Record<string, string>>()
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    getUserByToken().then((res) => {
      setUser(res.data.user)
    });
    getRequestTypes().then((res) => {
      var options: any[] = []
      res.data?.map(type => options.push({ value: type.type, label: type.label }))
      setRequestTypesOptions(options)
    });
  }, []);

  const [formData, setFormData] = useState<FormState>({
    urlType: 'web',
    urlValue: 'https://',
    urlDescription: '',
    requestTypes: []
  });

  const handleTypesChange = (e: MultiValue<{ value: string; label: string; }>) => {
    const selectedTypes = e.map(item => item.value);
    setFormData({ ...formData, requestTypes: selectedTypes })
  };

  const handleFormError = (field: string, errorMessage: string) => {
    setFormErrors((prevErrors) => ({
      ...prevErrors,
      [field]: errorMessage,
    }));
  };

  const validateForm = () => {
    const errors: Record<string, string> = {};

    if (!formData.urlValue) {
      errors.urlValue = 'Per favore inserisci un valore';
    }

    if (!formData.urlDescription) {
      errors.urlDescription = 'Per favore inserisci un valore';
    }

    setFormErrors(errors);

    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (validateForm()) {
      setLoading(true)
      try {
        setLoading(false)
        const urlModel: UrlModel = {
          url: formData.urlValue,
          analyze: true,
          typeUrl: formData.urlType,
          description: formData.urlDescription,
          requestType: formData.requestTypes
        }
        handleClose(urlModel)
        setFormData({
          ...formData, urlType: 'web',
          urlValue: 'https://',
          urlDescription: '',
          requestTypes: []
        })
        const closeButton = document.getElementById('close-modal-btn');
        if (closeButton) {
          closeButton.click();
        }
        setFormErrors({})
      } catch (error) {
        handleFormError('serverError', HandleApiErrors(intl, error));
        setLoading(false)
      }
    } else {
      console.log('Form has errors. Please fix them.');
    }
    console.log('Form submitted with data:', formData);
  };


  return <div className="modal fade" tabIndex={-1} id={"kt_modal_add_url"}>
    <div className="modal-dialog modal-dialog-centered mw-600px">

      {/* FORM MODAL */}
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title">Inserisci nuovo URL</h5>
          <div
            className="btn btn-icon btn-sm btn-active-light-primary ms-2"
            data-bs-dismiss="modal"
            aria-label="Close">
            <i className="bi bi-x-lg fs-4"></i>
          </div>
        </div>
        {<div className="modal-body">
          <form onSubmit={handleSubmit} className="mx-auto mw-500px w-100 pt-5 pb-0 fv-plugins-bootstrap5 fv-plugins-framework" id="kt_modal_add_url_form">
            <div className="w-100">
              <div className="mb-13">
                <h2 className="mb-1">Nuovo URL competitor</h2>
                <div className="text-muted fw-semibold fs-5">
                  Aiutaci ad analizzare il tuo competitor
                </div>
              </div>

              <div className="fv-row mb-8 fv-plugins-icon-container fv-plugins-bootstrap5-row-valid">
                <label className="required fs-6 fw-semibold mb-2">Tipologia URL
                  <span className="text-muted fw-semibold fs-8 ms-2">(Scegli tra Web, Instragram o Facebook)</span>
                </label>
                <select className="form-select form-select-solid" data-control="select_type" data-placeholder="Seleziona una tipologia" name="url_type" aria-hidden="true" data-kt-initialized="1"
                  value={formData.urlType}
                  onChange={(event) => {
                    const selectedValue = event.target.value;

                    setFormData((prevFormData) => {
                      let updatedFormData = { ...prevFormData, urlType: selectedValue };

                      // Update urlValue based on the selected option
                      if (selectedValue === 'web') {
                        updatedFormData = { ...updatedFormData, urlValue: 'https://' };
                      } else if (selectedValue === 'instagram') {
                        updatedFormData = { ...updatedFormData, urlValue: 'https://www.instagram.com/#NOMEPROFILO' };
                      } else if (selectedValue === 'facebook') {
                        updatedFormData = { ...updatedFormData, urlValue: 'https://www.facebook.com/#NOMEPROFILO' };
                      }

                      return updatedFormData;
                    });
                  }}>
                  <option key={1} value='web'>Web</option>
                  <option key={2} value='instagram'>Instagram</option>
                  <option key={3} value='facebook'>Facebook</option>
                </select>
              </div>

              <div className="fv-row mb-8 fv-plugins-icon-container fv-plugins-bootstrap5-row-valid">
                <label className="required fs-6 fw-semibold mb-2">URL da inserire
                  <span className="text-muted fw-semibold fs-8 ms-2">(Es. https://www.....)</span>
                </label>
                <input type="text" className="form-control form-control-solid"
                  placeholder="Inseriesci un URL valido ..."
                  name="url_title"
                  value={formData.urlValue}
                  onChange={(value) => {
                    setFormData({ ...formData, urlValue: value.target.value })
                    if (!value.target.value) {
                      handleFormError('urlValue', 'Per favore inserisci un valore');
                    } else {
                      handleFormError('urlValue', '');
                    }
                  }}
                />
                {formErrors?.urlValue && (
                  <div className='fv-plugins-message-container'>
                    <div data-field='appname' data-validator='notEmpty' className='fv-help-block'>
                      {formErrors?.urlValue}
                    </div>
                  </div>
                )}
              </div>

              <div className="fv-row mb-8 fv-plugins-icon-container fv-plugins-bootstrap5-row-valid">
                <label className="required fs-6 fw-semibold mb-2">Descrizione
                  <span className="text-muted fw-semibold fs-8 ms-2">(Es. Home Page del mio competitor)</span>
                </label>
                <input type="text" className="form-control form-control-solid"
                  placeholder="Inseriesci una descrizione..."
                  name="url_desc"
                  value={formData.urlDescription}
                  onChange={(value) => {
                    setFormData({ ...formData, urlDescription: value.target.value })
                    if (!value.target.value) {
                      handleFormError('urlDescription', 'Per favore inserisci un valore');
                    } else {
                      handleFormError('urlDescription', '');
                    }
                  }}
                />
                {formErrors?.urlDescription && (
                  <div className='fv-plugins-message-container'>
                    <div data-field='appname' data-validator='notEmpty' className='fv-help-block'>
                      {formErrors?.urlDescription}
                    </div>
                  </div>
                )}
              </div>

              <div className="fv-row mb-8 fv-plugins-icon-container fv-plugins-bootstrap5-row-valid">
                <label className="required fs-6 fw-semibold mb-2">Tipologie
                  <span className="text-muted fw-semibold fs-8 ms-2">(Es. Novità, Promozione)</span>
                </label>

                <Select
                  className='react-select-styled react-select-solid'
                  classNamePrefix='react-select'
                  options={requestTypesOptions}
                  isMulti
                  onChange={handleTypesChange}
                  placeholder='Seleziona tipologie'
                />
              </div>

              {formErrors?.serverError && (
                <div className='fv-plugins-message-container mb-4 ms-1'>
                  <div data-field='appname' data-validator='notEmpty' className='fv-help-block'>
                    {formErrors?.serverError}
                  </div>
                </div>
              )}
            </div>
            <div className="modal-footer mb-0">
              <div className='d-flex flex-stack'>
                <button id="close-modal-btn" type="button" className="btn btn-lg btn-light me-3" data-bs-dismiss="modal">Chiudi</button>
                <button type="submit" className="btn btn-lg btn-primary">
                  {!loading && <span className='indicator-label'>Conferma</span>}
                  {loading && (
                    <span className='indicator-progress' style={{ display: 'block' }}>
                      Salvataggio in corso...
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  )}
                </button>
              </div>
            </div>
          </form>
        </div>}
        {/* END FORM MODAL */}
      </div>
    </div >
  </div >
}

export { AddSignupUrlModal }
