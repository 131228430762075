import React, { useState, useEffect } from 'react'
import { useParams } from "react-router-dom";
// import { getAnalysisEventSuggestion } from './core/_requests'
import SidebarDrawer from "../main/SidebarDrawer/SidebarDrawer";
import { deleteProduct, editProduct, getProductDetails } from './core/_requests';
import { KeywordTrendGroup, ProductModel } from './core/_models';
import Chart from "react-apexcharts";
import { Button } from 'react-bootstrap';
import { AddCompetitorProductModal } from './modals/AddCompetitorProductModal';
import { useAuth } from '../../modules/auth';

const ProductDetailsPage: React.FC = () => {

  let { productId } = useParams()
  const { currentUser } = useAuth()
  const [loading, setLoading] = useState(false)
  const [product, setProduct] = useState<ProductModel | null>(null)
  const [chartOptions, setChartOptions] = useState<any>([]);
  const [miniChartOptions, setMiniChartOptions] = useState<any>([]);
  const [competitorsMiniChartOptions, setCompetitorsMiniChartOptions] = useState<any>([]);
  const [chartSeries, setChartSeries] = useState<any>([]);
  const [miniChartSeries, setMiniChartSeries] = useState<any>([]);
  const [competitorsMiniChartSeries, setCompetitorsMiniChartSeries] = useState<any>([]);
  const [competitorKeywordList, setCompetitorKeywordList] = useState<KeywordTrendGroup[]>([]);
  const [showChart, setShowChart] = useState(false);

  interface SeriesData {
    name: string;
    data: { x: string; y: number }[];
  }

  const formatDateToMonthYear = (dateString: string): string => {
    const date = new Date(dateString);
    const options: any = { year: 'numeric', month: 'long' };
    return date.toLocaleDateString(undefined, options);
  }


  async function generateChartOptions() {
    // console.log("D " + products[products.length - 1].productPrices[products[products.length - 1].productPrices.length - 1].updatedAt.substring(0, 10));
    // console.log("G " + products[0].productPrices[0].updatedAt.substring(0, 10));
    setChartOptions({
      chart: {
        id: 'chart',
        height: 350,
        type: 'line',
        fontFamily: 'inherit',
        dropShadow: {
          enabled: true,
          color: '#000',
          top: 10,
          left: 5,
          blur: 10,
          opacity: 0.15
        },
        toolbar: {
          show: false
        },
        animations: {
          enabled: false,
        },
        selection: {
          enabled: false
        },
        zoom: {
          enabled: false,
          type: 'x',
          autoScaleYaxis: false,
        }
      },
      grid: {
        borderColor: '#F2F2F2',
        strokeDashArray: 4,
        yaxis: {
          lines: {
            show: true
          }
        }
      },
      dataLabels: {
        enabled: true,
      },
      stroke: {
        curve: 'smooth'
      },
      markers: {
        size: 0
      },
      xaxis: {
        type: 'datetime',
        // min: new Date("2023-11-03").getTime(),
        max: new Date().getTime(),
        // min: new Date(products[products.length - 1].productPrices[products[products.length - 1].productPrices.length - 1].updatedAt.substring(0, 10)).getTime(),
        // max: new Date(products[0].productPrices[0].updatedAt.substring(0, 10)).getTime(),
        axisBorder: {
          show: false,
          offsetY: 0,
        },
        axisTicks: {
          show: false
        },
        labels: {
          style: {
            colors: '#a1a5b7',
            fontSize: '12px'
          }
        }
      },
      yaxis: {
        axisBorder: {
          show: false,
          offsetX: 10,
          offsetY: 10
        },
        axisTicks: {
          show: false,
        },
        // tickAmount: 2,
        labels: {
          align: 'left',
          style: {
            colors: '#a1a5b7',
            fontSize: '12px'
          },
          offsetX: -15
        },
      },
      legend: {
        show: true,
        position: 'bottom',
        horizontalAlign: 'left',
        floating: false,
        showForSingleSeries: false,
        offsetY: 8,
        offsetX: -5,
      }
    });

    setMiniChartOptions({
      chart: {
        id: 'miniChart',
        height: 50,
        type: 'area',
        fontFamily: 'inherit',
        redrawOnParentResize: true,
        parentHeightOffset: 0,
        sparkline: {
          enabled: true
        },
        stroke: {
          curve: 'smooth'
        },
        fill: {
          opacity: 1,
          type: 'gradient'
        },
        toolbar: {
          show: false
        },
        animations: {
          enabled: false,
        },
        selection: {
          enabled: false
        },
        zoom: {
          enabled: false,
          type: 'x',
          autoScaleYaxis: false,
        },
        dataLabels: {
          enabled: false,
        }
      },
      tooltip: {
        enabled: true,
        fixed: {
          enabled: true,
          position: 'center',
          offsetX: 0,
          offsetY: 60,
        },
      },
      xaxis: {
        axisBorder: {
          show: false // Hide the y-axis line
        },
        axisTicks: {
          show: false // Hide the y-axis ticks
        },
        labels: {
          show: false // Hide the y-axis labels
        },
        lines: {
          show: false,
        }
      },
      yaxis: {
        min: 0,
        axisBorder: {
          show: false // Hide the y-axis line
        },
        axisTicks: {
          show: false // Hide the y-axis ticks
        },
        labels: {
          show: false // Hide the y-axis labels
        },
        lines: {
          show: false,
        },
        type: 'category'
      },
      legend: {
        show: false
      },
      dataLabels: {
        enabled: false
      },
      grid: {
        yaxis: {
          lines: {
            show: false
          }
        },
        padding: { left: 0, right: 0, top: 10, bottom: 10 },
      },
    });

    setCompetitorsMiniChartOptions({
      chart: {
        id: 'competitorsMiniChart',
        height: 50,
        type: 'area',
        fontFamily: 'inherit',
        redrawOnParentResize: true,
        parentHeightOffset: 0,
        sparkline: {
          enabled: true
        },
        stroke: {
          curve: 'smooth'
        },
        fill: {
          opacity: 1,
          type: 'gradient',
        },
        toolbar: {
          show: false
        },
        animations: {
          enabled: true,
        },
        selection: {
          enabled: false
        },
        zoom: {
          enabled: false,
          type: 'x',
          autoScaleYaxis: false,
        },
        dataLabels: {
          enabled: false,
        }
      },
      colors: ['#F28E20'],
      tooltip: {
        enabled: true,
        fixed: {
          enabled: true,
          position: 'center',
          offsetX: 0,
          offsetY: 60,
        },
      },
      xaxis: {
        axisBorder: {
          show: false // Hide the y-axis line
        },
        axisTicks: {
          show: false // Hide the y-axis ticks
        },
        labels: {
          show: false // Hide the y-axis labels
        },
        lines: {
          show: false,
        },
        type: 'category'
      },
      yaxis: {
        min: 0,
        axisBorder: {
          show: false // Hide the y-axis line
        },
        axisTicks: {
          show: false // Hide the y-axis ticks
        },
        labels: {
          show: false // Hide the y-axis labels
        },
        lines: {
          show: false,
        }
      },
      legend: {
        show: false
      },
      dataLabels: {
        enabled: false
      },
      grid: {
        yaxis: {
          lines: {
            show: false
          }
        },
        padding: { left: 0, right: 0, top: 10, bottom: 10 },
      },
    });
  }

  async function generateSeriesList(productDetails: ProductModel): Promise<SeriesData[]> {

    var products: ProductModel[] = []
    products.push(productDetails)
    productDetails?.competitorProducts?.map((prod) => products.push(prod))

    const seriesList: SeriesData[] = []

    await generateChartOptions();

    for (const prod of products) {

      const data: any[] = [];

      for (let i = 0; i < prod?.productPrices?.length; i++) {
        // data.push([new Date().getTime() - i * 24 * 60 * 60 * 1000, prod.productPrices[i].price])
        data.push([new Date(prod.productPrices[i].updatedAt.substring(0, 10)), prod.productPrices[i].price])
      }

      const seriesData: SeriesData = {
        name: prod?.productName + ' (' + prod?.productOwner?.brandName + ')',
        data: data.length > 5 ? data.slice(0, 5) : data
      };

      seriesList.push(seriesData);
    }
    return seriesList;
  }
  interface ChartData {
    name: string;
    series: SeriesData[];
  }

  async function generateMiniSeriesList(productDetails: ProductModel): Promise<ChartData[]> {

    const chartDataList: ChartData[] = [];

    await generateChartOptions();

    for (const keywordGroup of productDetails.keywordTrends) {
      const data: { x: string, y: number }[] = [];

      keywordGroup.keywordTrends?.forEach(keyword => {
        data.push({ x: formatDateToMonthYear(keyword.data_volume), y: keyword.search_volume });
      });

      const seriesData: SeriesData = {
        name: keywordGroup._id,
        data: data.slice(0, 12).reverse() // Ensure the data array is limited to 12 items
      };

      const chartData: ChartData = {
        name: keywordGroup._id,
        series: [seriesData]
      };

      chartDataList.push(chartData);
    }

    return chartDataList;
  }

  async function generateCompetitorsMiniSeriesList(productDetails: ProductModel): Promise<ChartData[]> {

    const chartDataList2: ChartData[] = [];

    const compKeywordList = productDetails.competitorProducts.reduce((acc: KeywordTrendGroup[], competitor: ProductModel) => {
      if (competitor.keywordTrends) {
        acc.push(...competitor.keywordTrends);
      }
      return acc;
    }, []);
    const uniqueCompKeywordList = Array.from(
      compKeywordList.reduce((map, item) => map.set(item._id, item), new Map()).values()
    );
    const orderedCompKeywordList = uniqueCompKeywordList.sort((a, b) => {
      // Get the search_volume of the first KeywordTrend in each group
      const aFirstSearchVolume = a.keywordTrends[0]?.search_volume || 0;
      const bFirstSearchVolume = b.keywordTrends[0]?.search_volume || 0;

      // Sort in descending order
      return bFirstSearchVolume - aFirstSearchVolume;
    });
    setCompetitorKeywordList(orderedCompKeywordList)

    await generateChartOptions();

    for (const keywordGroup of compKeywordList) {

      const data: { x: string, y: number }[] = [];

      keywordGroup.keywordTrends?.forEach(keyword => {
        data.push({ x: formatDateToMonthYear(keyword.data_volume), y: keyword.search_volume });
      });

      const seriesData2: SeriesData = {
        name: keywordGroup._id,
        data: data.slice(0, 12).reverse() // Ensure the data array is limited to 12 items
      };

      const chartData2: ChartData = {
        name: keywordGroup._id,
        series: [seriesData2]
      };

      chartDataList2.push(chartData2);

    }

    return chartDataList2;
  }

  async function manageProductDetails(productDetails: ProductModel): Promise<ProductModel> {
    console.log(productDetails)
    const editedProductDetails = await addVirtualProductToday(productDetails)

    if (editedProductDetails && editedProductDetails.competitorProducts) {
      for (let i = 0; i < editedProductDetails.competitorProducts.length; i++) {
        // console.log(editedProductDetails.competitorProducts[i])
        const competitorProduct = { ...editedProductDetails.competitorProducts[i] as ProductModel }
        const editedCompetitorProductDetails = await addVirtualProductToday(competitorProduct)

        editedProductDetails.competitorProducts[i] = editedCompetitorProductDetails
      }
      editedProductDetails.competitorProducts = removeDuplicates(editedProductDetails.competitorProducts, "_id")
      editedProductDetails.competitorProducts = editedProductDetails.competitorProducts.filter(product => product.analyze === true)
    }
    //push the edited product
    setProduct(editedProductDetails)
    return editedProductDetails
  }

  //add a price to TODAY if it is not present
  async function addVirtualProductToday(productDetails: ProductModel): Promise<ProductModel> {
    if (productDetails.productPrices.length > 0) {
      //get the last price from list
      const lastProductPrice = { ...productDetails.productPrices[0] }
      //check if the last price is of today
      if (lastProductPrice && lastProductPrice.updatedAt) {
        if (new Date(lastProductPrice.updatedAt.substring(0, 10)) !== new Date()) {
          const editedLastProductPrice = { ...lastProductPrice }
          editedLastProductPrice.updatedAt = new Date().toISOString()
          productDetails.productPrices.unshift(editedLastProductPrice)
        }
        // productDetails.productPrices = removeDuplicates(productDetails.productPrices, "_id")
        productDetails.productPrices = productDetails.productPrices.length > 6 ? productDetails.productPrices.slice(0, 6) : productDetails.productPrices
      }
    }

    return productDetails
  }

  function removeDuplicates<T>(array: T[], key?: keyof T): T[] {
    const seen = new Set();
    return array.filter(item => {
      const value = key ? item[key] : item;
      if (seen.has(value)) {
        return false;
      } else {
        seen.add(value);
        return true;
      }
    });
  }

  function getLabelClassColor(myPrice: number, comparePrice: number): string {
    if (myPrice > comparePrice) {
      return "badge-light-success"
    } else if (myPrice < comparePrice) {
      return "badge-light-danger"
    } else {
      return "badge-secondary"
    }
  }

  const fetchData = async () => {
    try {
      setLoading(true)
      const productDetails = await getProductDetails(productId)
      const editedProductDetails = await manageProductDetails(productDetails.data)
      const seriesList = await generateSeriesList(editedProductDetails)
      setChartSeries(seriesList)

      const miniSeriesList = await generateMiniSeriesList(editedProductDetails)
      console.log(miniSeriesList)
      setMiniChartSeries(miniSeriesList)

      const competitorsMiniSeriesList = await generateCompetitorsMiniSeriesList(editedProductDetails)
      console.log(competitorsMiniSeriesList)
      setCompetitorsMiniChartSeries(competitorsMiniSeriesList)

      setLoading(false)
      setShowChart(true)
    } catch (error) {
      console.error('Error fetching data:', error)
      setLoading(false)
    }
  };

  useEffect(() => {
    generateChartOptions();
    fetchData();
  }, []);

  const handleDeleteButtonClick = async (id: String) => {
    try {
      await deleteProduct(id)
      fetchData()
    } catch (error) {
      console.error(error)
    }
  }

  const handleFavouriteButtonClick = async (product: ProductModel) => {
    try {
      setLoading(true);
      await editProduct(product._id, product.productName, product.productUrl, !product.analyze)
      await fetchData()
    } catch (error) {
      console.error(error)
      setLoading(false)
    }
  }

  return (
    <>
      {<div className="d-flex flex-column flex-column-fluid bg-light">

        <div id="kt_app_content" className="flex-column-fluid" data-kt-app-page-loading-enabled="true" data-kt-app-page-loading={loading ? 'on' : 'off'}>
          <div className="page-loader flex-column bg-dark bg-opacity-25">
            <span className="spinner-border text-primary" role="status">
              <span className="visually-hidden">Loading...</span>
            </span>
          </div>
          <div className="d-flex">
            <div id="kt_app_content_container" className="app-container container-fluid">
              <div className="d-flex flex-row flex-column-fluid">
                <div className="vh-75 w-100">
                  <div className="row gy-5 g-xl-8">
                    <div className="row gy-5 g-xl-8">
                      <div className="col-xl-6 mb-xl-0">
                        <div className="card card-flush overflow-hidden h-md-100">
                          <div className="card-header py-5">
                            <h3 className="card-title align-items-start flex-column">
                              <span className="card-label fw-bold text-gray-900">{product?.productName ?? ''}</span>
                              <span className="text-gray-500 mt-1 fw-semibold fs-6">Il mio prodotto</span>
                            </h3>
                          </div>
                          <div className="card-body pt-0 my-2">
                            <div className="d-flex flex-wrap flex-sm-nowrap">
                              <div className='flex-grow-1'>
                                <div className="d-flex flex-column">
                                  <div className="alert alert-secondary d-flex align-items-center p-5 mb-5">
                                    <span className="svg-icon svg-icon-2hx svg-icon-secondary me-3">
                                      <i className="bi bi-diagram-3 fs-2x me-4"></i>
                                    </span>
                                    <div className="d-flex flex-column">
                                      <a href={product?.productUrl} target='_blank' rel="noreferrer"><div className="text-gray-900 fs-7">{product?.productUrl}</div></a>
                                      <div className="fs-6 text-gray-900 fw-semibold pe-7">{product?.productOwner?.brandName}</div>
                                    </div>
                                  </div>
                                </div>
                                <div className="mt-5">
                                  <h3 className="card-title align-items-start flex-column">
                                    <span className="card-label fw-bold text-gray-800">Andamento prezzi</span>
                                  </h3>
                                </div>
                                <div className="table-responsive">
                                  <table className="table table-row-dashed align-middle gs-0 gy-4 my-0">
                                    <thead>
                                      <tr className="text-start text-gray-500 fw-bold fs-8 text-uppercase gs-0">
                                        <th className="min-w-50px sorting">SKU</th>
                                        <th className="min-w-50px sorting">QUANTITA'</th>
                                        <th className="min-w-50px sorting">PREZZO</th>
                                        <th className="min-w-50px sorting">DATA</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {product?.productPrices != null && product?.productPrices.map((price, index) => {
                                        const date = new Date(price.updatedAt);
                                        return (
                                          <tr>
                                            <td>
                                              <div className="d-flex align-items-center">
                                                <div className="d-flex justify-content-start flex-column">
                                                  <span className="text-dark fw-bold mb-1 fs-7">{price.sku ?? '#00' + (index + 1)}</span>
                                                </div>
                                              </div>
                                            </td>
                                            <td>
                                              <span className="text-muted fw-semibold fs-7">{price?.siceProduct ?? '---'}</span>
                                            </td>
                                            <td>
                                              <span className="badge py-3 px-3 fs-8 badge-light-primary">{price?.price?.toFixed(2) ?? "0,00"}€</span>
                                            </td>
                                            <td>
                                              <div className="fs-8 fw-bold">{Intl.DateTimeFormat("it-IT", { year: "numeric", day: "2-digit", month: "short" }).format(date)}</div>
                                            </td>
                                          </tr>
                                        )
                                      })}
                                    </tbody>
                                  </table>
                                </div>
                              </div>


                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-6 mb-5 mb-xl-0">
                        <div className="card card-flush overflow-hidden h-md-100">
                          <div className="card-header py-5">
                            <h3 className="card-title align-items-start flex-column">
                              <span className="card-label fw-bold text-gray-900">Analisi prezzi</span>
                              <span className="text-gray-500 mt-1 fw-semibold fs-6">Andamento dei prezzi nel tempo</span>
                            </h3>
                          </div>
                          <div className="card-body pt-0 my-2">
                            <div id="chart">
                              {showChart && (
                                <Chart
                                  options={chartOptions}
                                  series={chartSeries}
                                  type="line"
                                  height={315}
                                />
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-xl-12 mb-xl-10">

                      <div className="card card-flush">
                        <div className="card-header pt-7">
                          <h3 className="card-title align-items-start flex-column">
                            <span className="card-label fw-bold text-gray-800">Prodotti competitors</span>
                            {currentUser && currentUser.role !== 'admin' && (
                              <span className="text-gray-500 mt-1 fw-semibold fs-6">
                                Hai aggiunto {product?.competitorProducts?.length} prodotti competitor su {currentUser?.plan?.product_competitors_max}
                              </span>)}
                          </h3>
                          <div className="card-toolbar">
                            <button type="button" className="btn btn-sm btn-primary"
                              disabled={currentUser && currentUser.role !== 'admin' && (product?.competitorProducts?.length ?? 0) >= currentUser?.plan?.product_competitors_max}
                              data-bs-toggle="modal" data-bs-target={"#kt_modal_add_competitor_url"}>
                              <i className="bi bi-plus-lg fs-6"></i>Aggiungi</button>
                            <AddCompetitorProductModal product={product!} handleClose={() => { window.location.reload(); }} />
                          </div>
                        </div>
                        <div className="card-body pt-0 my-2">
                          <div className="table-responsive">
                            <table className="table table-row-dashed align-middle gs-0 gy-4 my-0">
                              <thead>
                                <tr className="text-start text-gray-500 fw-bold fs-8 text-uppercase gs-0">
                                  <th className="min-w-100px sorting">NOME PRODOTTO</th>
                                  <th className="min-w-120px sorting">OGGI</th>
                                  <th className="min-w-50px sorting">PREVISIONE 30GG</th>
                                  <th className="min-w-50px sorting">PREVISIONE 60GG</th>
                                  <th className="text-end min-w-30px sorting_disabled">COSA FARE</th>
                                </tr>
                              </thead>
                              <tbody>
                                {product?.competitorProducts != null && product?.competitorProducts.map(competitor => {
                                  return (
                                    <>
                                      <tr>
                                        <td>
                                          <div className="d-flex align-items-center">
                                            <div className="d-flex justify-content-start flex-column">
                                              <span className="text-dark fw-bold mb-1 fs-5">{competitor?.productName}</span>
                                              <span className="text-muted fw-semibold d-block fs-7">{competitor?.productOwner?.brandName}</span>
                                            </div>
                                          </div>
                                        </td>
                                        <td>
                                          {competitor?.productPrices.length > 0 &&
                                            <span className={`badge py-3 px-3 fs-7 ${getLabelClassColor(competitor?.productPrices[0]?.price, product?.productPrices[0]?.price)}`}>{competitor?.productPrices[0]?.price?.toFixed(2) ?? "0,00"}€</span>
                                          }
                                        </td>
                                        <td>
                                          {competitor?.productPrices.length > 0 &&
                                            <span className={`badge py-3 px-3 fs-7 ${getLabelClassColor(competitor?.productForecast?.fc30gg, product?.productPrices[0]?.price)}`}>{competitor?.productForecast?.fc30gg?.toFixed(2) ?? "---"}€</span>
                                          }
                                        </td>
                                        <td>
                                          {competitor?.productPrices.length > 0 &&
                                            <span className={`badge py-3 px-3 fs-7 ${getLabelClassColor(competitor?.productForecast?.fc60gg, product?.productPrices[0]?.price)}`}>{competitor?.productForecast?.fc60gg?.toFixed(2) ?? "---"}€</span>
                                          }
                                        </td>
                                        <td className="text-end">
                                          {competitor?._id != null && <Button className="btn btn-sm btn-icon btn-delete btn-light-danger me-2"
                                            onClick={() => { if (window.confirm('Sei sicuro di voler eliminare questo elemento?')) handleDeleteButtonClick(competitor?._id) }}>
                                            <i className="fa fa-trash-can fa-regular">
                                            </i>
                                          </Button>}
                                          {competitor?.productUrl != null &&
                                            <a className="btn btn-sm btn-icon btn-secondary me-2" href={competitor?.productUrl} target='_blank' rel="noreferrer">
                                              <i className={"fas fa-arrow-up-right-from-square"}>
                                              </i>
                                            </a>
                                          }
                                          {competitor?._id != null && <Button className="btn btn-sm btn-icon btn-secondary" onClick={() => handleFavouriteButtonClick(competitor)}>
                                            <i className={`${competitor?.analyze ? "fa fa-heart" : "fa fa-heart fa-regular"}`}>
                                            </i>
                                          </Button>}
                                        </td>
                                      </tr>
                                    </>
                                  )
                                })}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>

                      <div className="row gy-5 g-xl-8 mt-0">
                        <div className="col-xl-6 mb-xl-0">
                          <div className="card card-flush overflow-hidden h-md-100">
                            <div className="card-header py-5">
                              <h3 className="card-title align-items-start flex-column">
                                <span className="card-label fw-bold text-gray-900">Keywords per {product?.productName ?? ''}</span>
                                <span className="text-gray-500 mt-1 fw-semibold fs-6">{product?.productOwner?.brandName}</span>
                              </h3>
                            </div>
                            <div className="card-body pt-0 my-2">
                              <div className="d-flex flex-wrap flex-sm-nowrap">
                                <div className='flex-grow-1'>
                                  <div className="table">
                                    <table className="table table-row-dashed align-middle gs-0 gy-4 my-0">
                                      {product?.keywordTrends && product.keywordTrends.length > 0 ? (<thead>
                                        <tr className="text-start text-gray-500 fw-bold fs-8 text-uppercase gs-0">
                                          <th className="min-w-50px sorting">KEYWORDS</th>
                                          <th className="min-w-50px sorting">VOLUMI DI RICERCA</th>
                                          <th className="min-w-50px sorting">TRENDS</th>
                                        </tr>
                                      </thead>) : null}
                                      <tbody>
                                        {product?.keywordTrends && product.keywordTrends.length > 0 ? (
                                          product.keywordTrends.map((keywordGroup, index) => (
                                            <tr key={index}>
                                              <td>
                                                <div className="d-flex align-items-center">
                                                  <div className="d-flex justify-content-start flex-column">
                                                    <span className="text-dark fw-bold mb-1 fs-7">
                                                      {keywordGroup.keywordTrends[0]?.keyword}
                                                    </span>
                                                  </div>
                                                </div>
                                              </td>
                                              <td>
                                                <span className="text-dark fw-semibold fs-7">
                                                  {keywordGroup.keywordTrends[0]?.search_volume ?? '0'}
                                                </span>
                                              </td>
                                              <td className="pt-0 pb-0">
                                                <div id="miniChart">
                                                  {showChart && miniChartSeries.length > index && (
                                                    <Chart
                                                      options={miniChartOptions}
                                                      series={miniChartSeries[index].series}
                                                      type="area"
                                                      height={60}
                                                      width={140}
                                                    />
                                                  )}
                                                </div>
                                              </td>
                                            </tr>
                                          ))
                                        ) : (
                                          <div className="d-flex justify-content-center align-items-center">
                                            <div data-kt-search-element="empty" className="text-center">
                                              {/* Icon */}
                                              <div className="pt-10 pb-10">
                                                <i className="ki-outline ki-graph-3 fs-4x opacity-50"></i>
                                              </div>
                                              {/* Message */}
                                              <div className="pb-15 fw-semibold">
                                                <h3 className="text-gray-600 fs-5 mb-2">Nessuna keyword disponibile</h3>
                                                <div className="text-muted fs-7">La nostra AI sta analizzando le keywords e valutando i volumi di ricerca su Google.</div>
                                              </div>
                                            </div>
                                          </div>
                                        )}
                                      </tbody>
                                    </table>
                                  </div>
                                </div>


                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-xl-6 mb-5 mb-xl-0">
                          <div className="card card-flush overflow-hidden h-md-100">
                            <div className="card-header py-5">
                              <h3 className="card-title align-items-start flex-column">
                                <span className="card-label fw-bold text-gray-900">Keywords prodotti competitors</span>
                                <span className="text-gray-500 mt-1 fw-semibold fs-6"></span>
                              </h3>
                            </div>
                            <div className="card-body pt-0 my-2">
                              <div className="d-flex flex-wrap flex-sm-nowrap">
                                <div className='flex-grow-1'>
                                  <div className="table">
                                    <table className="table table-row-dashed align-middle gs-0 gy-4 my-0">
                                      {competitorKeywordList && competitorKeywordList.length > 0 ? (<thead>
                                        <tr className="text-start text-gray-500 fw-bold fs-8 text-uppercase gs-0">
                                          <th className="min-w-50px sorting">KEYWORDS</th>
                                          <th className="min-w-50px sorting">VOLUMI DI RICERCA</th>
                                          <th className="min-w-50px sorting">TRENDS</th>
                                        </tr>
                                      </thead>) : null}
                                      <tbody>
                                        {competitorKeywordList && competitorKeywordList.length > 0 ? (
                                          competitorKeywordList.map((keywordGroup2, index) => (
                                            <tr key={index}>
                                              <td>
                                                <div className="d-flex align-items-center">
                                                  <div className="d-flex justify-content-start flex-column">
                                                    <span className="text-dark fw-bold mb-1 fs-7">
                                                      {keywordGroup2.keywordTrends[0]?.keyword}
                                                    </span>
                                                  </div>
                                                </div>
                                              </td>
                                              <td>
                                                <span className="text-dark fw-semibold fs-7">
                                                  {keywordGroup2.keywordTrends[0]?.search_volume ?? '0'}
                                                </span>
                                              </td>
                                              <td className="pt-0 pb-0">
                                                <div id="miniChart">
                                                  {competitorsMiniChartSeries.length > index && (
                                                    <Chart
                                                      key={'competitor-products-chart-' + index}
                                                      options={competitorsMiniChartOptions}
                                                      series={competitorsMiniChartSeries[index].series}
                                                      type="area"
                                                      height={60}
                                                      width={140}
                                                    />
                                                  )}
                                                </div>
                                              </td>
                                            </tr>
                                          ))
                                        ) : (
                                          <div className="d-flex justify-content-center align-items-center">
                                            <div data-kt-search-element="empty" className="text-center">
                                              {/* Icon */}
                                              <div className="pt-10 pb-10">
                                                <i className="ki-outline ki-graph-3 fs-4x opacity-50"></i>
                                              </div>
                                              {/* Message */}
                                              <div className="pb-15 fw-semibold">
                                                <h3 className="text-gray-600 fs-5 mb-2">Nessuna keyword disponibile</h3>
                                                <div className="text-muted fs-7">La nostra AI sta analizzando le keywords e valutando i volumi di ricerca su Google.</div>
                                              </div>
                                            </div>
                                          </div>
                                        )}
                                      </tbody>
                                    </table>
                                  </div>
                                </div>


                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="mt-8">
              <SidebarDrawer />
            </div> */}
          </div>
        </div>
      </div>}
    </>
  );
};

export default ProductDetailsPage;
