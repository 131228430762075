import { MenuItem } from './MenuItem'

export function MainMenu() {
  return (
    <>
      {/* <MenuItem title={intl.formatMessage({ id: 'MENU.DASHBOARD' })} to='/dashboard' /> */}
      <MenuItem title='Analisi' to='/analysis/signals' />
      <MenuItem title='E-commerce' to='/products' />
      <MenuItem title='Consigli' to='/advice' />
      {/* <div className="menu-item menu-lg-down-accordion menu-sub-lg-down-indention me-0 me-lg-2">
        <span className="menu-link text-muted">
          <span className="menu-title">Consigli</span>
          <div className="w-5px"></div>
          <span className="badge badge-light-primary">Coming soon</span>
        </span>
      </div>
      <div className="menu-item menu-lg-down-accordion menu-sub-lg-down-indention me-0 me-lg-2">
        <span className="menu-link text-muted">
          <span className="menu-title ">Previsione</span>
          <div className="w-5px"></div>
          <span className="badge badge-light-primary">Coming soon</span>
        </span>
      </div> */}
    </>
  )
}
