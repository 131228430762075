import { useState } from 'react';
import { useEffect } from 'react';
import { KTIcon, toAbsoluteUrl } from '../../../_metronic/helpers';
import { ICreateSignupData, defaultCreateSignupData } from './ISignupModels';
import { createPortal } from 'react-dom';
import { buySubscription, getUserByToken } from './core/_requests';
import { HandleApiError } from '../../core/helpers/errorHandling';
import { useIntl } from 'react-intl';
import { UserModel } from './core/_models';
import { SubscriptionPlan } from '../../pages/analysis/core/_models';
import { getSubscriptionPlans } from '../../pages/analysis/core/_requests';

// const plans = [
//     {
//         id: 0,
//         title: 'Megan',
//         subTitle: 'Ideale per piccole aziende con pochi prodotti',
//         description: 'Ideale per piccole aziende con pochi prodotti',
//         priceMonth: '130',
//         discountedPriceMonth: '75',
//         priceAnnual: '999',
//         discountedPriceAnnual: '499',
//         // label: 'Risparmio del 50% il primo mese',
//         label: null,
//         stripe_monthly_id: 'price_1PVb0JBTjutVJGyE3ro8qX50',
//         stripe_annual_id: 'price_1PVb0JBTjutVJGyE3ro8qX50',
//         bmix_plan_id: '665844fa6d5c979a0332567c',
//         default: true,
//         custom: false,
//         features: [
//             {
//                 title: 'Max 30 URL da scansionare',
//                 supported: true,
//             },
//             {
//                 title: 'Max 1 Progetti da seguire',
//                 supported: true,
//             },
//             {
//                 title: 'Max 100 Prodotti da aggiungere',
//                 supported: true,
//             },
//             {
//                 title: 'Max 5 Prodotti competitors da osservare',
//                 supported: true,
//             },
//         ],
//     },
//     {
//         id: 1,
//         title: 'Martina',
//         subTitle: 'Consigliato per grosse aziende con centinaia di prodotti',
//         description: 'Consigliato per grosse aziende con centinaia di prodotti',
//         priceMonth: '290',
//         discountedPriceMonth: '145',
//         priceAnnual: '999',
//         discountedPriceAnnual: '499',
//         // label: 'Risparmio del 50% il primo mese',
//         label: null,
//         stripe_monthly_id: 'price_1PVb23BTjutVJGyEsU2lRP3d',
//         stripe_annual_id: 'price_1PVb23BTjutVJGyEsU2lRP3d',
//         bmix_plan_id: '665845cc6d5c979a0332567d',
//         default: false,
//         custom: false,
//         features: [
//             {
//                 title: 'Max 100 URL da scansionare',
//                 supported: true,
//             },
//             {
//                 title: 'Max 1 Progetti da seguire',
//                 supported: true,
//             },
//             {
//                 title: 'Max 300 Prodotti da aggiungere',
//                 supported: true,
//             },
//             {
//                 title: 'Max 20 Prodotti competitors da osservare',
//                 supported: true,
//             },
//         ],
//     },
//     {
//         id: 2,
//         title: 'Influencer',
//         subTitle: 'Ideale per Influencer che vogliono controllare il marketin dei competitors',
//         description: 'Ideale per Influencer che vogliono controllare il marketin dei competitors',
//         priceMonth: '60',
//         discountedPriceMonth: '30',
//         priceAnnual: '720',
//         discountedPriceAnnual: '600',
//         // label: 'Risparmio del 50% il primo mese',
//         label: null,
//         stripe_monthly_id: 'price_1PuT9BBTjutVJGyEv2olRti3',
//         stripe_annual_id: 'price_1PuTAcBTjutVJGyEvrMMy1Yp',
//         bmix_plan_id: '66d553d9521eb1ff1ce917e8',
//         default: false,
//         custom: false,
//         features: [
//             {
//                 title: 'Max 60 URL da scansionare',
//                 supported: true,
//             },
//             {
//                 title: 'Max 1 Progetto da seguire',
//                 supported: true,
//             },

//         ],
//     }
// ]


const SignupSelectPlanPage = () => {

    const intl = useIntl()
    const [user, setUser] = useState<UserModel>()
    const [loading, setLoading] = useState(false)
    const [errors, setErrors] = useState<Record<string, string>>();
    const [subscriptionPlans, setSubscriptionPlans] = useState<SubscriptionPlan[]>()

    const [data, setData] = useState<ICreateSignupData>(defaultCreateSignupData)
    const [hasError, setHasError] = useState(false)

    const [selectedPlan, setSelectedPlan] = useState<SubscriptionPlan>()
    const [currentState, setCurrentState] = useState<'month' | 'annual'>('month')

    useEffect(() => {
        getUserByToken().then((res) => {
            setUser(res.data.user)
        });
        getSubscriptionPlans().then((res) => {
            const plans = res.data
            setSubscriptionPlans(res.data)
            setSelectedPlan(plans?.find(plan => plan.default || plans[0]))
            updateData({
                appPlan: {
                    bmix_plan_id: selectedPlan?.bmix_plan_id,
                    stripe_monthly_id: selectedPlan?.stripe_monthly_id
                },
            })
        });
    }, []);

    const handleErrors = (field: string, errorMessage: string) => {
        setErrors((prevErrors) => ({
            ...prevErrors,
            [field]: errorMessage,
        }));
    };


    const modalsRoot = document.getElementById('root-modals') || document.body

    const updateData = (fieldsToUpdate: Partial<ICreateSignupData>) => {
        const updatedData = { ...data, ...fieldsToUpdate }
        setData(updatedData)
    }

    const checkAppPlan = (): boolean => {
        if (!data.appPlan.stripe_monthly_id || !data.appPlan.bmix_plan_id) {
            return false
        }

        return true
    }

    const submit = async () => {
        if (!checkAppPlan()) {
            setHasError(true)
            return
        }
        setLoading(true)
        try {
            const result = await buySubscription(data.appPlan.stripe_monthly_id, data.appPlan.bmix_plan_id)
            console.log(result.data.sessionUrl)
            setErrors(undefined)
            setLoading(false)
            window.location.href = result.data.sessionUrl
        } catch (error) {
            console.error(error)
            handleErrors('serverError', HandleApiError(intl, error));
            setLoading(false)
            setHasError(true)
            return
        }
    }

    const handlePlanChange = (planTitle: string) => {
        const plan = subscriptionPlans?.find(p => p.title === planTitle);
        if (plan) {
            setSelectedPlan(plan)
            updateData({
                appPlan: {
                    bmix_plan_id: plan.bmix_plan_id,
                    stripe_monthly_id: plan.stripe_monthly_id
                },
            })
        }
    };

    return createPortal(
        <div id="kt_modal_create_app">
            <div className="d-flex flex-column flex-root" id="kt_app_root">
                <div className="d-flex flex-column flex-lg-row flex-column-fluid stepper stepper-pills stepper-column stepper-multistep" id="kt_create_account_stepper">

                    <div className="d-flex flex-column flex-lg-row-fluid py-0">
                        <div className='d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed'
                            style={{ backgroundColor: `#F2F2F2` }} >
                            {/* begin::Content */}
                            <div className='d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20'>
                                {/* begin::Logo */}
                                <a href='#' className='mb-12'>
                                    <img
                                        alt='Logo'
                                        src={toAbsoluteUrl('/media/logos/brainmix-logo.png')}
                                        className='theme-dark-show h-55px'
                                    />
                                    <img
                                        alt='Logo'
                                        src={toAbsoluteUrl('/media/logos/brainmix-logo.png')}
                                        className='theme-light-show h-55px'
                                    ></img>
                                </a>
                                {/* end::Logo */}
                                {/* begin::Wrapper */}
                                <div className='bg-body rounded shadow-sm p-10 p-lg-15 mx-auto col-xl-12 mb-5 mb-xl-10'>

                                    <div className='flex-row-fluid py-lg-5 px-lg-15'>

                                        <div className="mb-4">
                                            <h1 className="mb-1 text-dark">Non hai ancora un piano? Attivalo adesso!</h1>
                                            <div className="text-muted fs-5">
                                                Seleziona il piano più adatto alle tue esigenze e sottoscrivi un abbonamento a BrainMix
                                            </div>
                                        </div>

                                        <div className='mb-4'>

                                            <div className='modal-body pt-0 pb-5 px-5 px-xl-0'>
                                                <div className='d-flex flex-column'>

                                                    <div className='row mt-10'>
                                                        <div className='col-lg-6 mb-10 mb-lg-0'>
                                                            <div className='nav flex-column'>
                                                                {subscriptionPlans?.map((plan, index) => {
                                                                    return (
                                                                        <div
                                                                            onClick={() => {
                                                                                handlePlanChange(plan.title)
                                                                            }}
                                                                            className={
                                                                                `nav-link btn btn-outline btn-outline-dashed btn-color-dark d-flex flex-stack text-start p-6 ` +
                                                                                (index !== subscriptionPlans?.length - 1 && 'mb-6 ') +
                                                                                (plan.default && 'active ') +
                                                                                (!plan.custom && 'btn-active btn-active-primary ')
                                                                            }
                                                                            data-bs-toggle='tab'
                                                                            data-bs-target={`#kt_upgrade_plan_${index}`}
                                                                            key={index}
                                                                        >
                                                                            <div className='d-flex align-items-center me-2'>
                                                                                <div className='form-check form-check-custom form-check-solid form-check-success me-6'>
                                                                                    <input
                                                                                        className='form-check-input'
                                                                                        type='radio'
                                                                                        name='plan'
                                                                                        value={plan.title}
                                                                                        checked={selectedPlan?.title === plan.title}
                                                                                        onChange={(e) => handlePlanChange(e.target.value)}
                                                                                    />
                                                                                </div>

                                                                                <div className='flex-grow-1'>
                                                                                    <div className='d-flex align-items-center fs-2 fw-bold flex-wrap'>
                                                                                        {plan.title}
                                                                                    </div>
                                                                                    <div className='fw-semibold opacity-50'>{plan.subTitle}</div>
                                                                                    {plan.label && (
                                                                                        <span className='badge badge-light-success mt-2 fs-7'>
                                                                                            {plan.label}
                                                                                        </span>
                                                                                    )}
                                                                                </div>
                                                                            </div>

                                                                            <div className='ms-5 text-xl-end'>
                                                                                <span className='mb-2'>€</span>

                                                                                <span className='fs-2hx fw-bold'>
                                                                                    {currentState === 'month' ? plan.discountedPriceMonth : plan.discountedPriceAnnual}
                                                                                </span>

                                                                                <span className='fs-7 opacity-50'>
                                                                                    /<span data-kt-element='period'>{currentState === 'month' ? 'Mese' : 'Anno'}</span>
                                                                                </span>
                                                                                {/* <div>
                                                                                    <span className='mb-2 opacity-75'>dal 2° mese €</span>

                                                                                    <span className='fs-1x fw-bold'>
                                                                                        {currentState === 'month' ? plan.priceMonth : plan.priceAnnual}
                                                                                    </span>

                                                                                    <span className='fs-7 opacity-50'>
                                                                                        /<span data-kt-element='period'>{currentState === 'month' ? 'Mese' : 'Anno'}</span>
                                                                                    </span>
                                                                                </div> */}
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                })}
                                                            </div>
                                                        </div>

                                                        <div className='col-lg-6'>
                                                            <div className='tab-content rounded bg-light p-10'>
                                                                <div key={`custom_${selectedPlan?.id}`}>
                                                                    <div
                                                                        className={`tab-pane fade active show`}
                                                                        id={`kt_upgrade_plan_${selectedPlan?.title}`}
                                                                        key={selectedPlan?.id}>

                                                                        <div className='pb-5'>
                                                                            <h2 className='fw-bold text-dark'>Cosa include il tuo piano?</h2>

                                                                            <div className='text-gray-400 fw-semibold'>
                                                                                {selectedPlan?.description}
                                                                            </div>
                                                                        </div>

                                                                        <div className='pt-1'>
                                                                            {selectedPlan?.features!.map((feature, i) => {
                                                                                return (
                                                                                    <div
                                                                                        className={
                                                                                            `d-flex align-items-center` +
                                                                                            (i !== selectedPlan.features!.length - 1 && ' mb-7')
                                                                                        }
                                                                                        key={`${i}-${feature.title}`}
                                                                                    >
                                                                                        {feature.supported && (
                                                                                            <>
                                                                                                <span className='fw-semibold fs-5 text-gray-700 flex-grow-1'>
                                                                                                    {feature.title}
                                                                                                </span>

                                                                                                <KTIcon
                                                                                                    iconName='check-circle'
                                                                                                    className='fs-1 text-success'
                                                                                                />
                                                                                            </>
                                                                                        )}
                                                                                        {!feature.supported && (
                                                                                            <>
                                                                                                <span className='fw-semibold fs-5 text-gray-400 flex-grow-1'>
                                                                                                    {feature.title}
                                                                                                </span>
                                                                                                <KTIcon iconName='cross-circle' className='fs-1' />
                                                                                            </>
                                                                                        )}
                                                                                    </div>
                                                                                )
                                                                            })}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="modal-footer mt-10">
                                            <div className='d-flex flex-stack'>
                                                <button type="submit" className="btn btn-lg btn-primary mt-4" onClick={() => { submit() }}>
                                                    {!loading && <span className='indicator-label'>Completa iscrizione</span>}
                                                    {loading && (
                                                        <span className='indicator-progress' style={{ display: 'block' }}>
                                                            Caricamento in corso...
                                                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                                        </span>
                                                    )}
                                                </button>
                                            </div>
                                        </div>
                                        {/*end::Content */}
                                    </div>
                                </div>
                                {/* end::Wrapper */}
                            </div>
                        </div>
                    </div>
                    <div className="d-flex flex-column flex-lg-row-auto w-lg-350px w-xl-350px" style={{ backgroundColor: '#0091CF' }} >
                        <div className="d-flex flex-column position-lg-fixed top-0 bottom-0 w-lg-350px w-xl-350px bg-color-primary scroll-y bgi-size-contain bgi-no-repeat bgi-position-bottom" style={{ backgroundImage: `url(${toAbsoluteUrl('/media/misc/signup-right-bg.png')})` }}>
                        </div>
                    </div>
                </div>
            </div>
        </div>,
        modalsRoot
    )
};

export { SignupSelectPlanPage };
