import React from "react";
import { Link } from "react-router-dom"

const Dashboard: React.FC = () => {
  return (
    <>
      {/* begin::Content wrapper */}
      <div className="d-flex flex-column flex-column-fluid bg-light">
        <div id="kt_app_content_container" className="app-container  container-xxl ">

          <div className="card mt-10 mb-10">

            <div className="card-body">

              <div className="card-px text-center pt-15 pb-15">

                <h2 className="fs-2x fw-bold mb-0">Dashboard in costruzione</h2>

                <p className="text-gray-400 fs-4 fw-semibold py-7">Stiamo lavorando per offrirti un esperienza migliore</p>

                <Link to={"/analysis/signals"} className="btn btn-primary er fs-6 px-8 py-4">Vai ad Analisi</Link>

              </div>

              <div className="text-center pb-15 px-5">
                <img src="/media/illustrations/sketchy-1/2.png" alt="" className="mw-100 h-200px h-sm-325px"></img>
              </div>

            </div>

          </div>
        </div>
      </div>
      {/* end::Content wrapper */}
    </>
  );
};

export default Dashboard;
